import React, { useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { MapAnimationsStoreContext, FocusStatus } from '../../stores/mapanimations.store';
import { WebSocketStoreContext } from '../../stores/websocket.store';

const DirectionsComp = (props: any) => {

    const detection = useContext(MapAnimationsStoreContext);
    const websocketStore = useContext(WebSocketStoreContext);
    const [height, setHeight] = useState(0)

    //console.log("--focus: ", detection.focusStatus, "    ", FocusStatus.Focused, "    cond: ", detection.focusStatus == FocusStatus.Focused)
    //console.log("--detection: ", detection.focusedDetection)
    //console.log("-----: ", height)


    if (detection.focusedDetection) {
        for (var i = 0; i < Object.keys(websocketStore.activeDetections).length; i++) {
            let idx = Object.keys(websocketStore.activeDetections)[i]
            //console.log("----active: ", websocketStore.activeDetections[i][1])
            //console.log("----active: ", websocketStore.activeDetections[i][1][websocketStore.activeDetections[i][1].length - 1].drone.sn)
            //console.log("----active: ", websocketStore.activeDetections[i][1].length - 1)
            //console.log("----active: ", detection.focusedDetection)
            if (websocketStore.activeDetections[idx][1][websocketStore.activeDetections[idx][1].length - 1].drone.sn == detection.focusedDetection) {
                if (websocketStore.activeDetections[idx][1][websocketStore.activeDetections[idx][1].length - 1].fHeight != height) {
                    setHeight(websocketStore.activeDetections[idx][1][websocketStore.activeDetections[idx][1].length - 1].fHeight)
                    //console.log("--height: ", height)
                }
                break;
            }
        }
    }



    const vx = 10;
    const vy = 0;
    const vz = 1;

    var norm = Math.max(Math.abs(vx), Math.abs(vy));
    var vx2 = vx / norm;
    var vy2 = vy / norm;
    //console.log("norm: ", norm, "")


    return (
        <div style={{ width: "100%", height: "100%", borderRadius: "7px", display: "flex", flexDirection: "column" }}>
            <Container style={{ padding: "0" }}>
                <svg style={{ width: "150px", height: "150px", backgroundColor: "rgba(255,255,255,0)" }}>
                    <defs>
                        <pattern id="background" patternUnits="userSpaceOnUse" width="150px" height="150px">
                            <image href={require('../../images/brujula.png')} x="0" y="0" width="150px" height="150px" />
                        </pattern>
                    </defs>
                    <path d="M5,5
        l0,680 l980,0 l0,-680 l-980,0"
                        fill="url(#background)" />

                </svg>
            </Container>
        </div >
    );
};

export default DirectionsComp;

/*return (
        <div style={{ width: "100%", height: "100%", borderRadius: "7px", display: "flex", flexDirection: "column" }}>
            <Container style={{ padding: "0" }}>
                <svg style={{ width: "250px", height: "250px", backgroundColor: "rgba(255,255,255,0.5)" }}>
                    <defs>
                        <marker id="arrowhead" markerWidth="6" markerHeight="4"
                            refX="0" refY="2" orient="auto">
                            <polygon points="0 0, 6 2, 0 4" fill="#ff0000" />
                        </marker>
                        <pattern id="background" patternUnits="userSpaceOnUse" width="250px" height="250px">
                            <image href={require('../../images/brujula.png')} x="0" y="0" width="250px" height="250px" />
                        </pattern>
                    </defs>
                    <path d="M5,5
        l0,680 l980,0 l0,-680 l-980,0"
                        fill="url(#background)" />
                    <line x1="126" y1="126" x2={126 + (vx2 * 60)} y2={126 - (vy2 * 60)} stroke="#ff0000" stroke-width="4" marker-end="url(#arrowhead)" />

                </svg>
            </Container>
            {
                height > 0 ?
                    <Container style={{ padding: "0" }}>
                        <div style={{ width: "100px", height: "250px", backgroundColor: "rgba(255,255,255,0.5)" }}>
                            <div style={{ width: "100px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <span>Altura (m)</span>
                            </div>
                            <div style={{ width: "100px", height: "200px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span>200m</span>
                                <div style={{ margin: "0px 30px", width: "40px", height: "150px", position: "relative", border: "1px solid", borderRadius: "5px" }}>
                                    <div style={{ position: "absolute", bottom: "0", width: "100%", height: (height/1.5) + "px", backgroundColor: "orange", borderRadius: "3px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <span>{height}m</span>
                                    </div>
                                </div>
                                <span>0m</span>
                            </div>
                        </div>
                    </Container>
                    : null
            }
        </div >
    );*/