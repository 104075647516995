import { observable, toJS } from 'mobx';
import { createContext } from 'react';
import { createUser, deleteUser, getGroup, getGroupChildren } from '../api';


export enum GroupStatus {
    NotYet,
    Checking,
    Waiting,
    Succeded,
    Failed
}


class GroupDataStorage {
    @observable status = GroupStatus.NotYet;
    @observable group: any;
    @observable children: any;

    async getGroup() {
        this.status = GroupStatus.Checking;
        let response = await getGroup();
        if (!response[0]) {
            this.group = null;
            this.status = GroupStatus.Failed;
            return;
        }
        this.group = response[1];
        this.status = GroupStatus.Succeded;
        return;
    }
    async getChildren() {
        this.status = GroupStatus.Checking;
        let response = await getGroupChildren();
        if (!response[0]) {
            this.group = null;
            this.status = GroupStatus.Failed;
            return;
        }
        this.children = response[1];
        this.status = GroupStatus.Succeded;
        return;
    }

    async addUser(body: any) {
        let response: any = await createUser(body);
        if (!response) {
            alert("La solicitud de creación de usuario ha sido denegada. Por favor revise todos los campos y compruebe que son correctos.");
        } else {
            alert("La solicitud de creación de usuario ha sido tramitada con éxito. Una vez creado el usuario, se informará al Administrador Central.");
        }
        return;
    }

    async removeUser(userId: String) {
        let response: any = await deleteUser(userId);
        if (!response) {
            alert("El usuario seleccionado no se ha podido eliminar correctamente. Por favor contacte con su Administrador.");
        } else {
            alert("El usuario seleccionado se ha eliminado correctamente.");
        }
        return;
    }
}

export const GroupDataStoreContext = createContext(new GroupDataStorage());