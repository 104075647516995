import React, { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ActivePageStoreContext } from '../../../stores/activepage.store';

const LinkWithTooltip = (props: any) => {
    const activepageStore = useContext(ActivePageStoreContext);

    return (
        <OverlayTrigger
            key={props.name}
            placement={'bottom'}
            overlay={
                <Tooltip id={props.name}>
                    {props.text}
                </Tooltip>
            }
        >
            <Nav.Link /* href={'#' + props.name} */ onClick={() => activepageStore.changeActivePage(props.value)} className={activepageStore.activePage === props.value ? "active" : ""}>{props.name}</Nav.Link>
        </OverlayTrigger>
    );
};

export default LinkWithTooltip;