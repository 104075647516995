import { observable } from 'mobx';
import { createContext } from 'react';
import { getDrones, updateDrone, createDrone } from '../api';


export enum DronesStatus {
    NotYet,
    Checking,
    Waiting,
    Succeded,
    Failed
}


class DronesDataStorage {
    @observable drones: any = [];
    @observable status = DronesStatus.NotYet;

    async getDrones(page?: number, query?: string) {
        this.status = DronesStatus.Checking;
        let response = await getDrones(page ? page : 1, query ? query : "");
        if (!response[0]) {
            //this.drones = [];
            this.status = DronesStatus.Failed;
            return;
        }
        let prevLength = this.drones.length;
        if (!page || page == 1) {
            prevLength = 0;
            this.drones = [];
        }
        this.drones = this.drones.concat(response[1]);
        /*if (this.drones.length === prevLength && prevLength !== 0) {
            alert("La búsqueda no ha obtenido resultados")
        }*/
        this.status = DronesStatus.Succeded;
        return;
    }

    async updateDrone(droneId: String, body: any) {
        this.status = DronesStatus.Checking;
        let response = await updateDrone(droneId, body);
        if (!response) {
            //console.log("Error al cambiar la relación del dron")
            this.status = DronesStatus.Failed;
            return;
        }
        this.status = DronesStatus.Succeded;
        alert("La relación del dron ha sido modificada con éxito")
        return;
    }

    async createDrone(body: any) {
        this.status = DronesStatus.Checking;
        let response = await createDrone(body);
        if (!response) {
            //console.log("Error al crear el dron")
            this.status = DronesStatus.Failed;
            return;
        }
        this.status = DronesStatus.Succeded;
        alert("El dron ha sido creado con éxito")
        return;
    }

}


export const DronesDataStoreContext = createContext(new DronesDataStorage());