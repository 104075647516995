import { observable } from 'mobx';
import { createContext } from 'react';
import { getRegisters, getExcel, getPDF, getKML } from '../api';


export enum RegistersStatus {
    NotYet,
    Checking,
    Waiting,
    Succeded,
    Failed,
    LoadingMore
}

export enum activeRegistersStatus {
    Selected,
    Deselected
}

export enum FilterStatus {
    NotYet,
    Filtered
}


class RegistersDataStorage {
    @observable status = RegistersStatus.NotYet;
    @observable activeRegistersStatus = activeRegistersStatus.Deselected;
    @observable registers: any = [];
    @observable activeRegisters: any = [];
    @observable activeController: any = [];
    @observable showHeatmap: boolean = false;
    @observable downloadExcelStatus: any = FilterStatus.NotYet;
    @observable queryParams: string = '';

    async getRegisters(params: String) {
        this.status = RegistersStatus.Checking;
        getRegisters(params).then((response) => {
            if ((!response[0]) || (response[1].length === 0)) {
                this.registers = [];
                this.status = RegistersStatus.Failed;
                return;
            }
            this.registers = [];
            response[1].map((reg: any) => this.registers.push(reg));
            this.activeController = [];
            for (var i = 0; i < this.registers.length; i++) {
                this.activeController.push(false);
            }
            this.status = RegistersStatus.Succeded;
        })
        return;
    }

    async getMoreRegisters(params: string) {
        this.status = RegistersStatus.LoadingMore;
        getRegisters(params).then((response) => {
            if ((!response[0]) || (response[1].length === 0)) {
                this.status = RegistersStatus.Succeded;
                return;
            }
            response[1].map((reg: any) => this.registers.push(reg));
            for (var i = this.activeController.length; i < this.registers.length; i++) {
                this.activeController.push(false);
            }
            this.status = RegistersStatus.Succeded;
        })
        return;
    }

    async getExcel(params: String) {
        let response = await getExcel(params);
        if (!response) {
            this.registers = [];
            return;
        }
        return;
    }

    async getPDF(params: String, sn: String, timeZoneOffset: number) {
        let response = await getPDF(params, sn, timeZoneOffset);
        if (!response) {
            this.registers = [];
            return;
        }
        return;
    }

    async getKML(params: String, sn: String) {
        let response = await getKML(params, sn);
        if (!response) {
            this.registers = [];
            return;
        }
        return;
    }
}

export const RegistersDataStoreContext = createContext(new RegistersDataStorage());