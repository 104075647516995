import React, { useContext, useState } from "react";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { observer } from "mobx-react-lite";
import Table from "react-bootstrap/Table";

import {
  UserDataStoreContext,
  UserStatus,
} from "../../../stores/userdata.store";
import { GroupDataStoreContext } from "../../../stores/groupdata.store";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useTranslation } from "react-i18next";
import { disable2FA, enable2FA, requestOTP } from "../../../api";

const ProfilePage = observer(() => {
  const [t, i18n] = useTranslation("common");

  const userdataStore = useContext(UserDataStoreContext);
  const groupdataStore = useContext(GroupDataStoreContext);

  const roles: any = {
    SUPERADMIN: "ADMINISTRADOR CENTRAL",
    ADMIN: "ADMINISTRADOR DEPARTAMENTAL",
    SUPERUSER: "ADMINISTRADOR LOCAL",
    USER: "USUARIO",
  };

  const [showPopUp, setShowPopUp] = useState(false);

  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(
    userdataStore.user.isTwoFactorEnabled
  );

  // Function to request OTP
  const handleRequestOTP = async () => {
    try {
      setErrorMessage(``);
      const message = await requestOTP(phoneNumber);
      setOtpSent(true);
      setInfoMessage(message); // Set informational message
    } catch (error) {
      console.error("Error requesting OTP:", error);
      setErrorMessage(`Failed to send OTP.`); // Set error message
    }
  };

  // Function to enable 2FA
  const handleEnable2FA = async () => {
    try {
      setErrorMessage(``);
      const message = await enable2FA(phoneNumber, otp);
      setIsTwoFactorEnabled(true); // Update 2FA status
      setOtpSent(false); // Reset OTP sent status
      setInfoMessage(message);
    } catch (error) {
      console.error("Error requesting OTP:", error);
      setErrorMessage(`Failed to send OTP.`); // Set error message
    }
  };

  // Function to disable 2FA
  const handleDisable2FA = async () => {
    try {
      setErrorMessage(``);
      const message = await disable2FA();
      setIsTwoFactorEnabled(false); // Update 2FA status
      setInfoMessage(message);
    } catch (error) {
      console.error("Error disabling 2FA:", error);
      setErrorMessage(`Failed to send OTP.`); // Set error message
    }
  };

  async function handleSubmit() {
    await userdataStore.changePassword({
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  if (userdataStore.passwordStatus == 3) {
    localStorage.removeItem("userToken");
    //window.location.reload(false);
  }

  return (
    <div>
      <Container
        style={{ padding: "0px", justifyContent: "center", display: "flex" }}
        fluid
      >
        <Card style={{ width: "50vw", textAlign: "center", marginTop: "10vh" }}>
          <Card.Header
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5 style={{ margin: "0" }}>
              {t("pages.profile.header")} {userdataStore.user.name}
            </h5>
            <ButtonGroup>
              <Button variant="primary" onClick={() => setShowPopUp(true)}>
                {t("pages.profile.btn-change-password")}
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  localStorage.removeItem("userToken");
                  window.location.reload();
                }}
              >
                {t("pages.profile.btn-logout")}
              </Button>
            </ButtonGroup>
          </Card.Header>
          <Card.Body>
            <Table responsive style={{ textAlign: "left" }}>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold", borderTop: "none" }}>
                    {t("pages.profile.name")}
                  </td>
                  <td style={{ borderTop: "none" }}>
                    {userdataStore.user.name}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("pages.profile.email")}
                  </td>
                  <td>{userdataStore.user.email}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("pages.profile.role")}
                  </td>
                  <td>{roles[userdataStore.user.role]}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {t("pages.profile.group")}
                  </td>
                  <td>{groupdataStore.group.name}</td>
                </tr>
              </tbody>
            </Table>

            {/* 2FA Section */}
            {!isTwoFactorEnabled ? (
              <div
                style={{
                  backgroundColor: "rgb(153, 187, 255, 0.5)",
                  padding: "20px",
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
              >
                <h6 style={{ textAlign: "left", marginBottom: "20px" }}>
                  {"🆕! Secure your account with 2FA "}
                </h6>
                <p style={{ textAlign: "left", marginBottom: "20px" }}>
                  Enter your mobile number beginning with the country code,
                  omitting any leading zeros, spaces, or the '+' sign. For
                  example, if your number is +34 9xx xxx xxx, you should enter
                  it as 349xxxxxxxx.
                </p>

                <p>
                  {errorMessage ? (
                    <Alert variant="danger">{errorMessage}</Alert>
                  ) : infoMessage ? (
                    <Alert variant="info">{infoMessage}</Alert>
                  ) : null}
                </p>
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      <b>{"Phone number"}</b>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder={"Please introduce your phone number"}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        disabled={otpSent}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={{ span: 8, offset: 4 }}>
                      {!otpSent ? (
                        <Button onClick={handleRequestOTP}>
                          Send OTP Code
                        </Button>
                      ) : (
                        <>
                          <Form.Control
                            type="text"
                            placeholder={"Enter the OTP sent to your phone"}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          <Button onClick={handleEnable2FA}>
                            Verify Phone Number
                          </Button>
                        </>
                      )}
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            ) : (
              <Button onClick={handleDisable2FA}>Disable 2FA</Button>
            )}
          </Card.Body>
        </Card>
      </Container>
      {showPopUp ? (
        <Modal show={showPopUp} onHide={() => setShowPopUp(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("pages.profile.form.header")}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Form style={{ width: "100%" }}>
              <Form.Group controlId="formGroupOldPass">
                <Form.Label style={{ fontWeight: "bold" }}>
                  {t("pages.profile.form.current-password")}
                </Form.Label>
                <Form.Control
                  onChange={(v) => setOldPassword(v.target.value)}
                  type="password"
                  placeholder={t(
                    "pages.profile.form.current-password-placeholder"
                  )}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formGroupNewPass">
                <Form.Label style={{ fontWeight: "bold" }}>
                  {t("pages.profile.form.new-password")}
                </Form.Label>
                <Form.Control
                  onChange={(v) => setNewPassword(v.target.value)}
                  type="password"
                  placeholder={t("pages.profile.form.new-password-placeholder")}
                  required
                />
              </Form.Group>
            </Form>
            {userdataStore.passwordStatus === UserStatus.Failed ? (
              <Row className="margin-top" style={{ width: "100%" }}>
                <Col style={{ width: "100%" }}>
                  <Alert variant="danger">
                    <Alert.Heading>
                      {t("pages.profile.form.error.header")}
                    </Alert.Heading>
                    <p>{t("pages.profile.form.error.content")}</p>
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              {t("pages.profile.form.btn-confirmation")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
});

export default ProfilePage;
