import { observable } from 'mobx';
import { createContext } from 'react';
import { getModels } from '../api';


export enum ModelsStatus {
    NotYet,
    Checking,
    Waiting,
    Succeded,
    Failed
}


class ModelsDataStorage {
    @observable models: any = [];
    @observable status = ModelsStatus.NotYet;

    async getModels() {
        this.status = ModelsStatus.Checking;
        let response = await getModels();
        if (!response[0]) {
            this.models = [];
            this.status = ModelsStatus.Failed;
            return;
        }
        this.models = response[1];
        this.status = ModelsStatus.Succeded;
        return;
    }
}


export const ModelsDataStoreContext = createContext(new ModelsDataStorage());