import { observable, toJS } from 'mobx';
import { createContext } from 'react';
import { getUser, changePassword } from '../api';


export enum UserStatus {
    NotYet,
    Checking,
    Waiting,
    Succeded,
    Failed
}



class UserDataStorage {
    @observable status = UserStatus.NotYet;
    @observable user: any;
    @observable passwordStatus = UserStatus.NotYet;

    async getUser() {
        this.status = UserStatus.Checking;
        let response: any = await getUser();
        if (!response[0]) {
            this.user = null;
            this.status = UserStatus.Failed;
            return;
        }
        this.user = response[1];
        this.status = UserStatus.Succeded;
        return;
    }

    async changePassword(body: any){
        this.passwordStatus = UserStatus.Checking;
        let response: any = await changePassword(body);
        if (!response) {
            this.passwordStatus = UserStatus.Failed;
            return;
        }
        this.passwordStatus = UserStatus.Succeded;
        return;
    }
}

export const UserDataStoreContext = createContext(new UserDataStorage());