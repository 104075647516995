import { observable, toJS } from 'mobx';
import { createContext } from 'react';
import { getGroupInhibitors, getInhibitors } from '../api';

export enum InhibitorsStatus {
    Loading,
    Loaded,
    CouldNotLoad
}

class InhibitorsStorage {
    @observable inhibitors: any = [];
    @observable status: InhibitorsStatus = InhibitorsStatus.Loading;
    @observable selectedInhibitor: any = null;

    async getInhibitors() {
        this.status = InhibitorsStatus.Loading;
        let response = await getInhibitors();
        if (!response[0]) {
            this.inhibitors = [];
            this.status = InhibitorsStatus.CouldNotLoad;
            return;
        }
        this.inhibitors = response[1];
        this.status = InhibitorsStatus.Loaded;
        return;
    }

    async getGroupInhibitors(groupId: String) {
        this.status = InhibitorsStatus.Loading;
        let response = await getGroupInhibitors(groupId);
        if (!response[0]) {
            this.inhibitors = [];
            this.status = InhibitorsStatus.CouldNotLoad;
            return;
        }
        this.inhibitors = response[1];

        if (this.selectedInhibitor) {
            let selectedInhibId = this.selectedInhibitor._id;
            let newSelectedInhib = this.inhibitors.find((i: any) => i._id === selectedInhibId);
            this.selectedInhibitor = newSelectedInhib;
        }

        this.status = InhibitorsStatus.Loaded;
        return;
    }
}

export const InhibitorsStoreContext = createContext(new InhibitorsStorage());