import { observable } from 'mobx';
import { createContext } from 'react';
import { getGroupDetectors } from '../api';

export enum DetectorsStatus {
    Loading,
    Loaded,
    CouldNotLoad
}

class DetectorsStorage {
    @observable detectors : any = [];
    @observable status : DetectorsStatus = DetectorsStatus.Loading;

    async getGroupDetectors(groupId: String) {
        this.status = DetectorsStatus.Loading;
        let response = await getGroupDetectors(groupId);
        if (!response[0]){
            this.detectors = [];
            this.status = DetectorsStatus.CouldNotLoad;
            return;
        }
        this.detectors = response[1];
        this.status = DetectorsStatus.Loaded;
        return;
    }
}

export const DetectorsStoreContext = createContext(new DetectorsStorage());