import React, { useContext, useEffect, useState } from 'react';


import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { observer } from 'mobx-react-lite';

import { GroupDataStoreContext } from '../../../stores/groupdata.store';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toJS } from 'mobx';
import { Accordion, ListGroup, ListGroupItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleLeft, faAngleRight, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';


const UsersManagementPage = observer(() => {

    const [t, i18n] = useTranslation("common");

    const groupdataStore = useContext(GroupDataStoreContext);


    async function loadData() {
        await groupdataStore.getChildren();
        console.log(toJS(groupdataStore.children))
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <div>
            <Container style={{ padding: "0px", justifyContent: "center", display: "flex" }} fluid>
                <Row style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                    <GroupsInfo children={toJS(groupdataStore.children)} reloadData={loadData} />
                    <AddUser children={toJS(groupdataStore.children)} reloadData={loadData} />
                </Row>
            </Container>
        </div>
    );
});

const GroupsInfo = (props: { children: Array<any>, reloadData: Function }) => {

    const [t, i18n] = useTranslation("common");

    const groupDataStore = useContext(GroupDataStoreContext);

    const [group, setGroup] = useState<any>();

    useEffect(() => {
        setGroup(props.children);
    }, [props.children])


    async function handleUserDelete(userId: String) {
        if (window.confirm(t("pages.management.groups.window-confirm"))) {
            await groupDataStore.removeUser(userId);
            await props.reloadData();
        }
    }

    return (
        <Card style={{ width: "40vw", textAlign: "center", marginTop: "10vh", maxHeight: "70vh", overflowY: "auto" }}>
            <Card.Header style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <h5 style={{ margin: "0" }}>{t("pages.management.groups.header")}</h5>
            </Card.Header>
            <Card.Body>
                <ListGroup>
                    {
                        group ?
                            <GroupsTree group={group} idx={0} handleUserDelete={handleUserDelete} />
                            :
                            null
                    }
                </ListGroup>
            </Card.Body>
        </Card>
    )
}

const GroupsTree = (props: { group: any, idx: number, handleUserDelete: Function }) => {

    const [t, i18n] = useTranslation("common");

    const [showChildren, setShowChildren] = useState(false)

    let group = props.group;


    function renderUsersInfo(users: Array<any>) {

        return users.map((user) => {
            return (
                <ListGroupItem style={{ display: "flex" }} key={"user_" + user._id}>
                    {user.email}
                    <OverlayTrigger
                        key={"info_" + user._id}
                        placement='bottom'
                        overlay={
                            <Tooltip id='centerLocation'>
                                {t("pages.management.groups.btn-delete-user")}
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ marginLeft: "auto", cursor: "pointer", color: "gray" }} onClick={() => { props.handleUserDelete(user["_id"]) }} />
                    </OverlayTrigger>
                </ListGroupItem>
            )
        });
    }

    return (
        <>
            <Accordion style={{ width: "100%", height: "100%", marginBottom: "1rem", paddingLeft: "calc( " + 25 * props.idx + "px )" }}>
                <Card>
                    <Card.Header style={{ display: "flex", alignItems: "center", backgroundColor: "white", padding: ".5rem 1rem" }}>
                        {
                            props.group.children.length > 0 ?
                                <FontAwesomeIcon icon={showChildren ? faAngleDown : faAngleRight} style={{ cursor: "pointer", marginRight: ".5rem" }} onClick={(e: any) => { e.stopPropagation(); setShowChildren(!showChildren) }} />
                                :
                                null
                        }
                        <h5 style={{ margin: "0" }}>
                            {group.name}
                        </h5>
                        <Accordion.Toggle eventKey="-2" style={{ backgroundColor: "white", marginLeft: "auto", border: "0" }}>
                            <OverlayTrigger
                                key={"info_" + group._id}
                                placement='bottom'
                                overlay={
                                    <Tooltip id='centerLocation'>
                                        {t("pages.management.groups.btn-show-children-hover")} {group.name}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    onClick={(e: any) => { /*getGroupInfo(e, group);*/ }}
                                    style={{ marginLeft: "auto" }} >
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </OverlayTrigger>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="-2">
                        <Card.Body style={{ padding: '1.25rem 1.25rem' }}>
                            {renderUsersInfo(group.users)}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            {
                showChildren ?
                    <>
                        {
                            group.children.map((child: any) => {
                                return <GroupsTree group={child} idx={props.idx + 1} handleUserDelete={props.handleUserDelete} />
                            })
                        }
                    </>
                    :
                    null
            }
        </>
    )
}

const AddUser = observer((props: { children: any, reloadData: Function }) => {

    const [t, i18n] = useTranslation("common");

    const groupDataStore = useContext(GroupDataStoreContext);

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [group, setGroup] = useState("");
    const [role, setRole] = useState("");
    const [identification, setIdentification] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [childrenGroups, setChildrenGroups] = useState<Array<any>>([]);

    useEffect(() => {
        cleanData();
    }, [])

    function cleanData() {
        setUsername("");
        setEmail("");
        setPassword("");
        setGroup("");
        setRole("");
    }

    function getGroups(children: Array<any>, group: any, idx: number) {
        for (var child of group.children) {
            children.push({ id: child._id, name: child.name, idx: idx })
            children = getGroups(children, child, idx + 1);
        }
        return children;
    }

    useEffect(() => {
        if (props.children) {
            let aux = []
            let children: Array<any> = getGroups([{ id: props.children._id, name: props.children.name, idx: 0 }], props.children, 1);
            for (var i = 0; i < children.length; i++) {
                let group = toJS(children[i]);
                aux.push(
                    <option value={group.id} key={group.id}>{group.name}</option>
                )
            }
            setChildrenGroups(aux);
        }
    }, [props.children])

    function validateDNI() {
        var numero: any
        var letr: string
        var letra: string
        var expresion_regular_dni: any

        expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

        if (expresion_regular_dni.test(identification) == true) {
            numero = identification.substr(0, identification.length - 1);
            letr = identification.substr(identification.length - 1, 1);
            numero = numero % 23;
            letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(numero, numero + 1);
            if (letra != letr.toUpperCase()) {
                return false
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    async function handleSubmit() {
        //await userdataStore.changePassword({ oldPassword: oldPassword, newPassword: newPassword });
        if (!username || !email || !password || !group || !role || !identification || !phoneNumber || phoneNumber.length !== 9) {
            alert(t("pages.management.users.alert-invalid-fields"));
            return
        }
        if (validateDNI()) {
            await groupDataStore.addUser({
                name: username,
                email: email,
                password: password,
                group: group,
                role: role,
                identification: identification,
                phoneNumber: phoneNumber
            })
            cleanData();
            await props.reloadData();
        } else {
            alert(t("pages.management.users.alert-invalid-identifier"));
        }
    }

    return (
        <Card style={{ width: "40vw", textAlign: "center", marginTop: "10vh", maxHeight: "70vh", overflowY: "auto" }}>
            <Card.Header style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <h5 style={{ margin: "0" }}>{t("pages.management.users.header")}</h5>
            </Card.Header>
            <Card.Body>
                <Col>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.management.users.username")}</Form.Label>
                        <Form.Control value={username} onChange={(v) => setUsername(v.target.value)} type="text" placeholder={t("pages.management.users.username-placeholder")} />
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.management.users.email")}</Form.Label>
                        <Form.Control value={email} onChange={(v) => setEmail(v.target.value)} type="text" placeholder={t("pages.management.users.email-placeholder")} />
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.management.users.password")}</Form.Label>
                        <Form.Control value={password} onChange={(v) => setPassword(v.target.value)} type="password" placeholder={t("pages.management.users.password-placeholder")} />
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.management.users.identifier")}</Form.Label>
                        <Form.Control value={identification} onChange={(v) => v.target.value.length <= 9 ? setIdentification(v.target.value) : null} type="text" placeholder={t("pages.management.users.identifier-placeholder")} />
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.management.users.phone-number")}</Form.Label>
                        <Form.Control value={phoneNumber} onChange={(v) => v.target.value.length <= 9 ? setPhoneNumber(v.target.value.replace(/\s/g, "")) : null} type="number" placeholder={t("pages.management.users.phone-number-placeholder")} />
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.management.users.group")}</Form.Label>
                        <Form.Control value={group} onChange={(v) => setGroup(v.target.value)} as="select">
                            <option value={""}>-----------</option>
                            {childrenGroups}
                        </Form.Control>
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.management.users.role")}</Form.Label>
                        <Form.Control value={role} onChange={(v) => setRole(v.target.value)} as="select">
                            <option value={""}>-----------</option>
                            <option value={"ADMIN"}>ADMINISTRADOR DEPARTAMENTAL</option>
                            <option value={"SUPERUSER"}>ADMINISTRADOR LOCAL</option>
                            <option value={"USER"}>USUARIO AVANZADO</option>
                            <option value={"USER"}>USUARIO</option>
                        </Form.Control>
                    </Row>
                    <Button style={{ width: "50%", margin: '2rem' }} type="submit" onClick={() => handleSubmit()}>
                        <span style={{ marginLeft: '10px' }}>{t("pages.management.users.btn-add-user")}</span>
                    </Button>
                </Col>
            </Card.Body>
        </Card>)
})

export default UsersManagementPage;