import {  observable } from 'mobx';
import { createContext } from 'react';

export enum PageIndex {
    Realtime,
    Registros,
    Zonas,
    Drones,
    Detectores,
    Inhibidores,
    Gestión,
    Perfil,
    Configuración
}

class ActivePageStore {
    @observable activePage = PageIndex.Registros;

    async changeActivePage(newActivePage: PageIndex) {
        //console.log("Changed to: ", newActivePage);
        this.activePage = newActivePage;
        return;
    }
}

export const ActivePageStoreContext = createContext(new ActivePageStore());