import { observable, toJS } from "mobx";
import { createContext } from "react";
import L from "leaflet";

export enum FocusStatus {
  NotFocused,
  Focused,
}

class MapAnimationsStorage {
  @observable focusedlocation: any = [];
  @observable focusedZoom: number = 0;
  @observable fitBounds: any = {};
  @observable focusStatus: FocusStatus = FocusStatus.NotFocused;
  @observable focusedDetection: any = null;

  async focusOnDetection(detectionLog: any, detectors: any) {
    var points: any = [];
    var hasGPS = false;
    let totalDetectors: string[] = [];

    try {
        // Check if detectionLog is an object (JSON format with "center" and "route" fields) or an array of detection points
        if (Array.isArray(detectionLog[1])) {
            let detection = detectionLog[1];

            if (!detection || detection.length === 0) {
                throw new Error("No detection data available.");
            }

            this.focusedDetection = detection[0].drone.sn;

            for (var i = 0; i < detection.length; i++) {
                if (detection?.[i]?.detector && !totalDetectors.includes(detection[i].detector)) {
                    totalDetectors.push(detection[i].detector);
                }

                if (detection?.[i]?.droneLocation?.lat && detection?.[i]?.droneLocation?.lon &&
                    detection[i].droneLocation.lat !== 0 && detection[i].droneLocation.lon !== 0) {
                    hasGPS = true;
                    points.push([detection[i].droneLocation.lat, detection[i].droneLocation.lon]);
                }
            }
        } else if (detectionLog && detectionLog[1]?.route) {
            let detection = detectionLog[1];
            this.focusedDetection = detection.drone.sn;

            for (var i = 0; i < detection.route.length; i++) {
                if (detection.route[i].detector && !totalDetectors.includes(detection.route[i].detector)) {
                    totalDetectors.push(detection.route[i].detector);
                }

                if (detection.route[i]?.droneLocation?.lat && detection.route[i]?.droneLocation?.lon &&
                    detection.route[i].droneLocation.lat !== 0 && detection.route[i].droneLocation.lon !== 0) {
                    hasGPS = true;
                    points.push([detection.route[i].droneLocation.lat, detection.route[i].droneLocation.lon]);
                }
            }
        } else {
            throw new Error("Unsupported detectionLog format.");
        }

        // If GPS coordinates are not available, center on the first valid detector's location
        if (!hasGPS) {
            let foundDetector = null;

            // Iterate through totalDetectors to find the first matching detector in detectors array
            for (let detectorId of totalDetectors) {
                let detector = detectors.find((det: any) => det._id === detectorId);
                if (detector) {
                    foundDetector = detector;
                    break; // Stop iterating once we find a valid detector
                }
            }

            if (foundDetector) {
                console.log("Focusing on found detector:", foundDetector);

                // Add the found detector's location to points
                points.push([foundDetector.location.lat, foundDetector.location.lon]);

                // Center the map at the detector's location
                this.fitBounds = L.latLngBounds(points);
                this.focusStatus = FocusStatus.Focused;
                setTimeout(() => (this.focusStatus = FocusStatus.NotFocused), 300);
            } else {
                console.error("No matching detector found in the list of detectors.");
            }
        } else {
            // If GPS points are available, fit map to the drone's route
            this.fitBounds = L.latLngBounds(points);
            this.focusStatus = FocusStatus.Focused;
            setTimeout(() => (this.focusStatus = FocusStatus.NotFocused), 300);
        }
    } catch (error) {
        console.error("Error in focusOnDetection:", error);
    }
}


  async focusOnZone(zone: any) {
    if (zone.type === "CIRCLE") {
      let new_latitude1 =
        zone.center.lat + ((zone.radius + 50) / 6378000) * (180 / Math.PI);
      let new_longitude1 =
        zone.center.lon +
        (((zone.radius + 50) / 6378000) * (180 / Math.PI)) /
          Math.cos((zone.center.lat * Math.PI) / 180);
      let new_latitude2 =
        zone.center.lat - ((zone.radius + 50) / 6378000) * (180 / Math.PI);
      let new_longitude2 =
        zone.center.lon -
        (((zone.radius + 50) / 6378000) * (180 / Math.PI)) /
          Math.cos((zone.center.lat * Math.PI) / 180);
      this.fitBounds = new L.LatLngBounds([
        [new_latitude1, new_longitude1],
        [new_latitude2, new_longitude2],
      ]);
      this.focusStatus = FocusStatus.Focused;
      setTimeout(() => (this.focusStatus = FocusStatus.NotFocused), 1000);
    } else {
      this.fitBounds = L.latLngBounds(zone.perimeter);
      this.focusStatus = FocusStatus.Focused;
      setTimeout(() => (this.focusStatus = FocusStatus.NotFocused), 1000);
    }
  }

  async focusOnCenter() {
    var lat = localStorage.getItem("mapCenterLat")
      ? Number(localStorage.getItem("mapCenterLat"))
      : 39.538391691513816;
    var lon = localStorage.getItem("mapCenterLon")
      ? Number(localStorage.getItem("mapCenterLon"))
      : -0.06510968257644745;
    this.fitBounds = L.latLngBounds([
      [lat, lon - 6],
      [lat, lon + 4],
      [lat - 4, lon],
      [lat + 4, lon],
    ]);
    this.focusStatus = FocusStatus.Focused;
    setTimeout(() => (this.focusStatus = FocusStatus.NotFocused), 1000);
  }
}

export const MapAnimationsStoreContext = createContext(
  new MapAnimationsStorage()
);
