import React, { useContext, useEffect } from "react";

import loginBackground from "../../../images/login_background.jpg";
import asdt from "../../../images/loginLogos/ASDT_white.png";
import sendes from "../../../images/loginLogos/SENDES_white.png";

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

import LoginForm from "../components/loginform.component";
import { observer } from "mobx-react-lite";
import {
  LoginDataStoreContext,
  LoginStatus,
} from "../../../stores/logindata.store";
import { useTranslation } from "react-i18next";

const LoginPage = observer(() => {
  const [t, i18n] = useTranslation("common");
  const logindataStore = useContext(LoginDataStoreContext);

  //useEffect(() => console.log(window.location.hostname), [])

  function getLogo() {
    //console.log(window.location.hostname)
    return window.location.hostname.includes("siglo")
      ? require("../../../images/loginLogos/SIGLO.png")
      : window.location.hostname.includes("mossos")
      ? require("../../../images/loginLogos/MOSSOS.png")
      : window.location.hostname.includes("uavlatam") 
      ? require("../../../images/loginLogos/LogoUAVLatam.png")
      : require("../../../images/loginLogos/ASDT.png");
    // : require("../../../images/loginLogos/ASDT.png");
  }

  return (
    <>
      <img
        id="login-background"
        src={loginBackground}
        style={{
          opacity: "90%",
          objectFit: "cover",
          height: "100%",
          width: "100%",
          zIndex: -1,
          position: "fixed",
        }}
      />

      <Container
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container
          style={{
            width: "75vw",
            maxWidth: "600px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "10rem",

            position: "relative",
            top: "0px",
            left: "0px",
          }}
        >
          <Row style={{ margin: "2rem", width: "90vw", maxWidth: "400px" }}>
            <img
              src={getLogo()}
              style={{
                maxWidth: "75%",
                maxHeight: "75%",
                display: "block",
                margin: "auto",
              }}
            />
          </Row>
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoginForm></LoginForm>
          </Row>
          {logindataStore.status === LoginStatus.Failed ? (
            <Row className="margin-top">
              <Col></Col>
              <Col md={8}>
                <Alert variant="danger">
                  <Alert.Heading>{t("pages.login.error-header")}</Alert.Heading>
                  <p>{t("pages.login.error-msg")}</p>
                </Alert>
              </Col>
              <Col></Col>
            </Row>
          ) : null}
        </Container>
      </Container>

      <div
        style={{
          width: "50vw",
          maxWidth: "350px",
          display: "flex",
          position: "absolute",
          height: "auto",
          bottom: "3rem",
          left: "50%",
          transform: "translate(-50%, 0%)",
          justifyContent: "center",
          minWidth: "275px",
        }}
      >
        <img
          src={sendes}
          alt="sendes-logo"
          style={{ maxWidth: "100%", maxHeight: "40px" }}
        />
      </div>
    </>
  );
});

export default LoginPage;
