import { observable, toJS } from "mobx";
import { createContext } from "react";
import { v4 as uuid } from "uuid";

import { ActivePageStoreContext, PageIndex } from "../stores/activepage.store";

class WebsocketStorage {
  InhibitorsStoreContext: any;
  @observable websocket: any;
  @observable isWebsocketConnected: boolean = false;
  @observable activeDetections: any = {};
  @observable alarms: any = [];
  @observable alarmSound: HTMLAudioElement = new Audio(
    require("../audios/alarmSound.wav")
  );
  @observable isAlarmFired: boolean = false;
  @observable isAlarmStopped: boolean = false;
  @observable needsToRedraw: boolean = false;
  @observable showRealTime: boolean = false;
  @observable inhibitingInhibitors: any = {};
  @observable aircraft: any;
  @observable aircraftLastUpdate: any;

  @observable updateInhibitors: boolean = false;
  @observable updateDetectors: boolean = false;

  @observable selectedColors: any = {};

  @observable showAlertPopup: boolean = false;
  @observable alertPopupMessage: string = "";
  @observable couldNotConnect: boolean = false;
  notAbleToConnectCount: number = 0;
  @observable falsePositives: number = 0;

  alarmsTypes: any = {};
  alarmsStatus: any = {};

  /* alarmSound = new Audio(require('../audios/alarmSound.wav')); */

  rootUrl = window.location.origin;
  url: string = this.rootUrl.replace(/^http(s?):\/\//, "ws$1://") + "/api/ws";
  // url: string = "wss://sendesgate.asdt.eu/api/ws";
  // url: string = "ws://devserver.asdt.eu/api/ws";
  //url: string = "ws://localhost:8080";

  async deactivateAlarm(id: string) {
    for (var sn of Object.keys(this.alarmsStatus)) {
      this.alarmsStatus[sn] = false;
    }
  }

  playAlarmSound() {
    if (!this.isAlarmFired) {
      this.isAlarmFired = true;
      this.alarmSound.play();
      this.alarmSound.addEventListener("ended", () => {
        if (this.isAlarmFired) {
          this.alarmSound.play();
        }
      });
    }
  }

  stopAlarmSound() {
    if (this.isAlarmFired) {
      this.isAlarmFired = false;
      this.isAlarmStopped = true;
      this.alarmSound.pause();
    }
  }

  /* async playAlarmSound() {
        this.alarmSound.play();
        this.isAlarmFired = true;
    }

    async stopAlarmSound() {
        this.alarmSound.pause();
        this.isAlarmFired = false;
    } */

  async addNewAlarm(type: String, title: string, body: string, sn: string) {
    this.alarms.push({
      uid: uuid(),
      type: type,
      active: true,
      time: new Date(Date.now()),
      title: title,
      sn: sn,
      body: body,
    });
  }

  async connect(token: string) {
    this.websocket = new WebSocket(this.url);
    this.websocket.onerror = async () => {
      if (this.notAbleToConnectCount === 3) this.couldNotConnect = true;
      if (this.notAbleToConnectCount <= 2) {
        this.notAbleToConnectCount += 1;
        //console.log("Error on websocket, reconnecting...");
        this.isWebsocketConnected = false;
        await new Promise((resolve) => setTimeout(resolve, 10000));
        this.connect(token);
      }
    };
    this.websocket.onclose = async () => {
      //console.log("Closed websocket, reconnecting...");
      if (this.notAbleToConnectCount === 3) this.couldNotConnect = true;
      this.isWebsocketConnected = false;
      if (this.notAbleToConnectCount <= 2) {
        if (!this.showAlertPopup) {
          await new Promise((resolve) => setTimeout(resolve, 10000));
          this.connect(token);
        }
      }
    };

    this.websocket.onopen = () => {
      //console.log("Connected websocket!");
      this.isWebsocketConnected = true;
      this.websocket.send(JSON.stringify({ token: token }));
      this.notAbleToConnectCount = 0;
      this.couldNotConnect = false;
    };

    this.websocket.onmessage = (msg: any) => {
      //console.log("MESSAGE -> ", msg)
      let message = JSON.parse(msg.data);

      try {
        if (message.type === "aircraft") {
          //console.log("AIRCRAFT -> ", message.body)
          this.aircraft = { ...message.body };
          this.aircraftLastUpdate = new Date(message.timestamp);
        }

        if (message.type === "falsePositives") {
          const falsePositives = parseInt(message.body?.falsePositivesCount);
          if (falsePositives != null) this.falsePositives = falsePositives;
          // console.log(falsePositives);
        }

        if (message.type === "detection") {
          // console.log("NEWPOINT -> ", message.body)
          let gid = message.body.drone.generatedId;

          if (!gid) {
            throw new Error();
          }

          if (!this.selectedColors[message.body.drone.sn]) {
            this.selectedColors[message.body.drone.sn] =
              "rgb(" +
              Math.floor(Math.random() * 255) +
              " ," +
              Math.floor(Math.random() * 255) +
              "," +
              Math.floor(Math.random() * 255) +
              ")";
          }

          var found = false;

          if (this.activeDetections[gid]) {
            this.activeDetections[gid][0] = new Date(Date.now());
            this.activeDetections[gid][1].push(message.body);
            found = true;
            if (
              message.body.alarm &&
              message.body.alarm != null &&
              !this.isAlarmStopped
            ) {
              if (message.body.alarm === "ALARM") {
                this.playAlarmSound();
              }
              if (
                this.alarmsTypes[message.body.drone.sn] === "WARNING" &&
                message.body.alarm === "ALERT" &&
                message.body.drone.type != "FRIEND" &&
                !this.isAlarmStopped
              )
                this.playAlarmSound();
              if (
                this.alarmsTypes[message.body.drone.sn] === "ALERT" &&
                message.body.alarm === "ALARM" &&
                !this.isAlarmStopped
              )
                this.playAlarmSound();
              this.alarmsTypes[message.body.drone.sn] = message.body.alarm;
            }
          } else {
            this.showRealTime = true;
            this.activeDetections[gid] = [new Date(Date.now()), [message.body]];
            if (
              (message.body.alarm === "ALERT" &&
                (message.body.drone.type !== "MEDICAL" ||
                  message.body.drone.type !== "FRIEND")) ||
              message.body.alarm === "ALARM"
            ) {
              this.playAlarmSound();
              this.alarmsStatus[message.body.drone.sn] = true;
            } else {
              this.alarmsStatus[message.body.drone.sn] = false;
            }
            this.alarmsTypes[message.body.drone.sn] = message.body.alarm;
          }

          //   this.activeDetections.sort((a: any, b: any) => {
          //     if (a[0] < b[0]) return -1;
          //     if (a[0] > b[0]) return 1;
          //     return 0;
          //   });
        }

        if (message.type === "detectionFinished") {
          var index;
          delete this.selectedColors[message.body.sn];

          delete this.activeDetections[message.body.generatedId];

          if (this.activeDetections.length === 0) {
            this.isAlarmStopped = false;
            this.isAlarmFired = false;
          }
        }

        if (message.type === "inhibitorAlarm") {
          if (message.body.alarm && message.body.alarm != null) {
            this.addNewAlarm(
              message.body.alarm,
              "Alarma de inhibidor: " + message.body.alias,
              message.body.message,
              ""
            );
          }
        }
        if (message.type === "inhibition") {
          if (message.body.GPS || message.body.control) {
            //active
            if (this.inhibitingInhibitors[message.body.id]) {
              //exists in object (update it)
              delete this.inhibitingInhibitors[message.body.id];
              this.inhibitingInhibitors[message.body.id] = message.body;
              this.inhibitingInhibitors[message.body.id].time = new Date(
                Date.now()
              ).toLocaleString();
            } else {
              //does not exist in object yet (add it)
              this.inhibitingInhibitors[message.body.id] = message.body;
              this.inhibitingInhibitors[message.body.id].time = new Date(
                Date.now()
              ).toLocaleString();
            }
          } else {
            //deactivate
            if (this.inhibitingInhibitors[message.body.id]) {
              //exists in object
              delete this.inhibitingInhibitors[message.body.id];
            }
          }
        }
        if (message.type === "deviceStatus") {
          if (message.body.device.type == "inhibitor") {
            this.updateInhibitors = true;
          }
          if (message.body.device.type == "detector") {
            this.updateDetectors = true;
          }
        }
        if (message.type === "alertPopup") {
          this.alertPopupMessage = message.body.message;
          this.showAlertPopup = true;
        }

        // this.needsToRedraw = true;
      } catch {}
    };
  }
}

export const WebSocketStoreContext = createContext(new WebsocketStorage());
