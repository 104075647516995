import React from 'react';

import ZonesMap from '../components/zonesmap.component';
import ZonesColumn from '../components/zonescolumn.component';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




const ZonesPage = (() => {
    return(
        <Container className="all-app-remaining-height" style={{ padding: "0px" }} fluid>
            <Row noGutters>
                <Col xs={8} className="all-app-remaining-height" style={{ padding: "0px !important" }}>
                    <ZonesMap />
                </Col>
                <Col xs={4} className="all-app-remaining-height" style={{ padding: "10px", display: "flex", flexDirection: "column"}}>
                    <ZonesColumn />
                </Col>
            </Row>
        </Container>
    )
});

export default ZonesPage;