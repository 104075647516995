import React, { useContext, useEffect } from 'react';
import './App.css';
import LoginPage from './pages/login/page/loginpage';
import HomePage from './pages/home/page/homepage';
import { observer } from 'mobx-react-lite';
import { LoginDataStoreContext, LoginStatus } from './stores/logindata.store';
import { useTranslation } from 'react-i18next';

const App = observer(() => {
  const logindataStore = useContext(LoginDataStoreContext);
  const { t, i18n } = useTranslation("common");
  useEffect(() => {
    document.title = "SENDES Gate";
    logindataStore.checkStoredToken();
    i18n.changeLanguage(localStorage.getItem('language') || "en");
  }, []);

  return (
    <div className="App" style={{ height: "100vh", overflow: "hidden" }}>
      {
        logindataStore.status === LoginStatus.Succeded ?
          <HomePage></HomePage> :
          logindataStore.status === LoginStatus.Checking ?
            null
            :
            <LoginPage></LoginPage>
      }
    </div>
  );
});

export default App;
