import React, { useState, useContext, useEffect } from 'react';
import { ZonesDataStoreContext, ZonesStatus } from '../../stores/zonesdata.store';
import { DetectorsStoreContext, DetectorsStatus } from '../../stores/detectors.store';
import { InhibitorsStoreContext, InhibitorsStatus } from '../../stores/inhibitors.store';
import { UserDataStoreContext } from '../../stores/userdata.store';
import { WebSocketStoreContext } from '../../stores/websocket.store';
import { MapAnimationsStoreContext, FocusStatus } from '../../stores/mapanimations.store';
import { ActivePageStoreContext, PageIndex } from '../../stores/activepage.store';
import L from 'leaflet';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { observer } from 'mobx-react-lite';
import { RegistersDataStoreContext } from '../../stores/registersdata.store';
import { toJS } from 'mobx';
import Toast from 'react-bootstrap/Toast';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "styled-components";
import InhibitorComp from './InhibitorComp';
import { useTranslation } from 'react-i18next';
const HeatmapOverlay = require("leaflet-heatmap")

interface Hiddeable {
    visible: boolean
}
const DownloadingDocumentCard = styled.div<Hiddeable>`
width: 30rem;
height: 7rem;
background-color: white;
border-radius: 0.5rem;
position: absolute;
left: ${props => props.visible ? "10px" : "-35rem"};
bottom: 13.5rem;
z-index: 1000;
display: flex;
flex-direction: row;
padding: 1rem;
transition: left 0.25s ease;
`

const TestPage = observer((props: any) => {

    const [t, i18n] = useTranslation("common");
    const [toggle, setToggle] = useState(true);

    const [leafletMap, setLeafletMap]: any = useState(null);

    const [currentStyle, setCurrentStyle]: any = useState(null);
    const [activeLayers, setActiveLayers]: any = useState(props.defaultSwitchValues);
    const [tileLayer, setTileLayer]: any = useState([null]);
    const [autoTrack, setAutoTrack] = useState(false);
    const [autoInhibitionRadius, setAutoInhibitionRadius] = useState(0);
    const [showAutoInhibitionRadius, setShowAutoInhibitionRadius] = useState(false);
    const [trackedDrone, setTrackedDrone] = useState("")

    const [detectorsLayer, setDetectorsLayer]: any = useState(null);
    const [inhibitorsLayer, setInhibitorsLayer]: any = useState(null);
    const [zonesLayer, setZonesLayer]: any = useState(null);
    const [aircraftsLayer, setAircraftsLayer]: any = useState(null);
    const [registersLayer, setRegistersLayer]: any = useState(null);
    const [heatmapRegistersLayer, setHeatmapRegistersLayer]: any = useState(null);
    const [realtimeLayer, setRealtimeLayer]: any = useState(null);
    const [autoInhibitionLayer, setAutoInhibitionLayer]: any = useState(null);

    const [previousRegistersLength, setPreviousRegistersLength]: any = useState(0);
    const [previousActiveRegistersLength, setPreviousActiveRegistersLength]: any = useState(0);
    const [previousRealtimeStatus, setPreviousRealtimeStatus]: any = useState({});
    const [inhibToShow, setInhibToShow]: any = useState(0);
    const [inhibOutputs, setInhibOutputs]: any = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
    });

    const zonesStore = useContext(ZonesDataStoreContext);
    const inhibitorsStore = useContext(InhibitorsStoreContext);
    const detectorsStore = useContext(DetectorsStoreContext);
    const registersStore = useContext(RegistersDataStoreContext);
    const realtimeStore = useContext(WebSocketStoreContext);
    const mapAnimationStore = useContext(MapAnimationsStoreContext);
    const usersdataStore = useContext(UserDataStoreContext);
    const activePageStore = useContext(ActivePageStoreContext);
    const websocketStore = useContext(WebSocketStoreContext);

    var alarms: any = [];

    useEffect(() => {
        if (autoInhibitionLayer) {
            leafletMap.removeLayer(autoInhibitionLayer);
        }
        if (inhibitorsStore.selectedInhibitor && showAutoInhibitionRadius) {
            let layer = new L.LayerGroup();
            layer.addLayer(
                new L.Circle(
                    [inhibitorsStore.selectedInhibitor.location.lat, inhibitorsStore.selectedInhibitor.location.lon],
                    {
                        radius: autoInhibitionRadius === 0 ? inhibitorsStore.selectedInhibitor.inhibitionRadius : autoInhibitionRadius,
                        stroke: false,
                        fillColor: 'blue',
                        fillOpacity: 0.3
                    }
                )
            );
            layer.addTo(leafletMap);
            setAutoInhibitionLayer(layer);
        } else {

        }
    }, [inhibitorsStore.selectedInhibitor, autoInhibitionRadius])


    useEffect(() => {
        if (leafletMap && realtimeStore.aircraft && activeLayers[3] && props.showFlights && props.type === "realtime") {
            if (aircraftsLayer) {
                leafletMap.removeLayer(aircraftsLayer)
            }

            var aircrafts = L.geoJSON(realtimeStore.aircraft, {
                pointToLayer: function (feature, latlng) {
                    return new L.Marker(
                        latlng,
                        {
                            opacity: 0.5,
                            icon: L.divIcon({
                                html: `<img 
                                style="transform: rotate(${feature.properties.track}deg);"
                                height="25" 
                                width="25" 
                                src='./black_aircraft.png'>`
                            })
                        }
                    )
                        .bindTooltip(
                            `<p style="margin: 0"><b>Identificador: </b>${feature.properties.flight ? feature.properties.flight : "Desconocido"}</p>` +
                            `<p style="margin: 0"><b>Velocidad: </b>${feature.properties.speed ? (feature.properties.speed) + "kt" : "Desconocida"}</p>` +
                            `<p style="margin: 0"><b>Altitud: </b>${feature.properties.altitude ? (feature.properties.altitude) + "ft" : "Desconocida"}</p>`
                            // <Table responsive>
                            //     <tr>
                            //         <td style="font-weight: bold; border-top: none; margin-right: 1rem">Identificador</td>
                            //         <td style="border-top: none; margin-left: 1rem">`+ feature.properties.flight ? feature.properties.flight : "Desconocido" + `</td>
                            //     </tr>
                            //     <tr>
                            //         <td style="font-weight: bold; margin-right: 1rem">Velocidad</td>
                            //         <td style="margin-left: 1rem">`+ feature.properties.speed ? feature.properties.speed : "Desconocida" + `</td>
                            //     </tr>
                            //     <tr>
                            //         <td style="font-weight: bold; margin-right: 1rem">Altitud</td>
                            //         <td style="margin-left: 1rem">`+ feature.properties.altitude ? feature.properties.altitude : "Desconocida" + `</td>
                            //     </tr>
                            // </Table>
                            ,
                            {
                                sticky: true,
                                direction: "top"
                            }

                        );
                }
            }).addTo(leafletMap);
            setAircraftsLayer(aircrafts)
        }
    }, [realtimeStore.aircraft])

    function drawNoGPSRadius(layer: any, detectionPoint: any, center?: any) {
        let detector = detectorsStore.detectors.find((detector: any) => detector._id === detectionPoint.detector);
        if (center && center.lat && center.lon) {
            layer.addLayer(
                new L.Circle(
                    [center.lat, center.lon],
                    {
                        radius: 1000,
                        stroke: false,
                        fillColor: 'green',
                        fillOpacity: 0.3
                    }
                )
            );
        } else {
            layer.addLayer(
                new L.Circle(
                    [detector.location.lat, detector.location.lon],
                    {
                        radius: 1000,
                        stroke: false,
                        fillColor: 'green',
                        fillOpacity: 0.3
                    }
                )
            );
        }
    }

    async function openInhibitor(inhibitorIndex: number) {
        inhibitorsStore.selectedInhibitor = inhibitorsStore.inhibitors[inhibitorIndex];
        setInhibToShow(inhibitorIndex);
    }

    const createZonesLayer = () => {
        var circularZones = [];
        var polygonalZones = [];

        for (var i = 0; i < zonesStore.zones.length; i++) {
            let zone = zonesStore.zones[i];
            if (zone.type === "CIRCLE") {
                circularZones.push(zone);
            } else {
                polygonalZones.push(zone);
            }
        }

        let zonesLayer = new L.LayerGroup();

        circularZones.forEach((zone, index) => {
            zonesLayer.addLayer(
                new L.Circle(
                    [zone.center.lat, zone.center.lon],
                    {
                        radius: zone.radius,
                        color: zone.securityLevel === "ALERT" ? "orange" : zone.securityLevel === "ALARM" ? "red" : "yellow",
                        fill: true,
                        fillOpacity: zone.securityLevel === "ALERT" ? 0.3 : zone.securityLevel === "ALARM" ? 0.5 : 0.1,
                        weight: 6
                    }
                ).bindTooltip(
                    `
                <Table responsive>
                        <tr>
                            <td style="font-weight: bold; border-top: none">`+ t("zone.name") + `</td>
                            <td style="border-top: none">`+ zone.name + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold">`+ t("zone.level") + `</td>
                            <td>`+ zone.securityLevel + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold">`+ t("zone.center") + `</td>
                            <td>`+ [zone.center.lat, zone.center.lon] + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold">`+ t("zone.radius") + `</td>
                            <td>`+ zone.radius + `m</td>
                        </tr>
                    </Table>

                `,
                    {
                        sticky: true,
                        direction: "top"
                    }
                ));
        });

        polygonalZones.forEach((zone, index) => {
            zonesLayer.addLayer(
                new L.Polygon(
                    zone.perimeter,
                    {
                        color: zone.securityLevel === "ALERT" ? "orange" : zone.securityLevel === "ALARM" ? "red" : "yellow",
                        fill: true,
                        fillOpacity: zone.securityLevel === "ALERT" ? 0.3 : zone.securityLevel === "ALARM" ? 0.5 : 0.1,
                        weight: 6
                    }
                ).bindTooltip(
                    `
                    <Table responsive>
                            <tr>
                                <td style="font-weight: bold; border-top: none">`+ t("zone.name") + `</td>
                                <td style="border-top: none">`+ zone.name + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">`+ t("zone.level") + `</td>
                                <td>`+ zone.securityLevel + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">`+ t("zone.points") + `</td>
                                <td>`+ zone.perimeter.length.toString() + `</td>
                            </tr>
                        </Table>
    
                    `,
                    {
                        sticky: true,
                        direction: "top"
                    }
                ));
        });

        return zonesLayer;
    }

    const createInhibitorsLayer = () => {
        var inhibitors = [];

        for (var i = 0; i < inhibitorsStore.inhibitors.length; i++) {
            let inhibitor = inhibitorsStore.inhibitors[i];
            inhibitors.push(inhibitor);
        }

        let inhibitorsLayer = new L.LayerGroup();

        inhibitors.forEach((inhibitor, index) => {
            if (!inhibitor.isMobile || inhibitor.isActive) {
                let stringEstado = inhibitor.isActive ? t("inhibitor.status-connected") : t("inhibitor.status-disconnected");
                let icon;
                if (inhibitor.isActive) {
                    if (inhibitor.isMobile) {
                        icon = require("../../images/inh_port_conn.png")
                    } else {
                        if (inhibitor.isFixedMobile) {
                            icon = require("../../images/inh_port_conn.png")
                        } else {
                            icon = require("../../images/inh_est_conn.png")
                        }
                    }
                } else {
                    if (inhibitor.isMobile) {
                        icon = require("../../images/inh_port_disconn.png")
                    } else {
                        if (inhibitor.isFixedMobile) {
                            icon = require("../../images/inh_port_disconn.png")
                        } else {
                            icon = require("../../images/inh_est_disconn.png")
                        }
                    }
                }
                inhibitorsLayer.addLayer(
                    new L.Marker(
                        [inhibitor.location.lat, inhibitor.location.lon],
                        {
                            icon: new L.Icon({
                                iconUrl: icon,
                                iconSize: [55, 55],
                                iconAnchor: [55 / 2, 55]
                            })
                        }
                    )
                        .bindTooltip(
                            `
                    <Table responsive>
                        <tr>
                            <td style="font-weight: bold; border-top: none">`+ t("inhibitor.name") + `</td>
                            <td style="border-top: none">`+ inhibitor.name + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold">`+ t("inhibitor.location") + `</td>
                            <td>`+ [inhibitor.location.lat, inhibitor.location.lon] + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold ">`+ t("inhibitor.status") + `</td>
                            <td>`+ stringEstado + `</td>
                        </tr>
                    </Table>
    
                    `,
                            {
                                sticky: true,
                                direction: "top"
                            }
                        ).on('click', () => { if (props.type === "realtime" && usersdataStore.user?.role.includes("ADMIN")) { openInhibitor(index) } })
                );
            }
        });

        return inhibitorsLayer;
    }

    const createDetectorsLayer = () => {
        var detectors = [];

        for (var i = 0; i < detectorsStore.detectors.length; i++) {
            let detector = detectorsStore.detectors[i];
            detectors.push(detector);
        }

        let detectorsLayer = new L.LayerGroup();

        detectors.forEach((detector, index) => {
            if (!detector.isMobile || detector.isActive) {
                let stringEstado = detector.isActive ? t("detector.status-connected") : t("detector.status-disconnected");
                let location = detector.location
                let icon;
                if (detector.isActive) {
                    if (detector.isMobile) {
                        if (detector.lastStatus) {
                            if (detector.lastStatus.aeroscope && detector.lastStatus.aeroscope.connected) {
                                icon = require("../../images/det_port_conn.png")
                            } else {
                                icon = require("../../images/det_port_conn_no_aeroscope.png")
                            }
                        } else {
                            icon = require("../../images/det_port_conn.png")
                        }
                    } else {
                        if (detector.lastStatus) {
                            if (detector.lastStatus.aeroscope && detector.lastStatus.aeroscope.connected) {
                                if (detector.isFixedMobile) {
                                    icon = require("../../images/det_fm_conn.png")
                                } else {
                                    icon = require("../../images/det_est_conn.png")
                                }
                            } else {
                                if (detector.isFixedMobile) {
                                    icon = require("../../images/det_fm_conn_no_aeroscope.png")
                                } else {
                                    icon = require("../../images/det_est_conn_no_aeroscope.png")
                                }
                            }
                        } else {
                            if (detector.isFixedMobile) {
                                icon = require("../../images/det_fm_conn.png")
                            } else {
                                icon = require("../../images/det_est_conn.png")
                            }
                        }
                    }
                } else {
                    if (detector.isFixedMobile) {
                        icon = require("../../images/det_fm_disconn.png")
                    } else {
                        icon = require("../../images/det_est_disconn.png")
                    }
                }
                detectorsLayer.addLayer(
                    new L.Marker(
                        detector.lastStatus ? [location.lat, location.lon] : [location.lat, location.lon],
                        {
                            icon: new L.Icon({
                                iconUrl: icon,
                                iconSize: [55, 55],
                                iconAnchor: [55 / 2, 55]
                            })
                        }
                    ).bindTooltip(
                        `
                    <Table responsive>
                        <tr>
                            <td style="font-weight: bold; border-top: none">`+ t("detector.name") + `</td>
                            <td style="border-top: none">`+ detector.name + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold">`+ t("detector.location") + `</td>
                            <td>`+ [location.lat, location.lon] + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold ">`+ t("detector.status") + `</td>
                            <td>`+ stringEstado + `</td>
                        </tr>
                    </Table>
    
                    `,
                        {
                            sticky: true,
                            direction: "top"
                        }
                    )
                );
            }
        });

        return detectorsLayer;
    }

    const createRegistersLayer = () => {
        var registers = registersStore.activeRegisters.map((activeRegister: any) => toJS(activeRegister.detection));
        var detectors = detectorsStore.detectors.map((detector: any) => toJS(detector));
        let registersLayer = new L.LayerGroup();

        registers.forEach((register: any, index: number) => {
            if (register[0] && register[0].asdtId) {
                let detector = detectors.find((det: any) => det._id === register[0].detector);

                registersLayer.addLayer(
                    new L.Circle(
                        [detector.location.lat, detector.location.lon],
                        {
                            radius: 2000,
                            stroke: false,
                            fillColor: 'green',
                            fillOpacity: 0.3
                        }
                    )
                );
            } else {
                if (register[0]) {
                    var hasGPS = false;
                    let image = require("../../images/droneOrange.png")

                    if (registersStore?.activeRegisters?.[index]?.drone?.type) {
                        switch (registersStore.activeRegisters[index].drone.type) {
                            case "FRIEND":
                            case "AUTHORIZED":
                                image = require("../../images/droneGreen.png")
                                break;
    
                            case "STOLEN":
                                image = require("../../images/droneStolen.png")
                                break;
                            
                            case "FAKE":
                            case "NEUTRALIZED":
                                image = require("../../images/droneFake.png");
                                break;
                            
                            case "ENEMY":
                                image = require("../../images/droneRed.png");
                                break;
                            
                            case "NOTIFY":
                                image = require("../../images/droneBlue.png");
                                break;
    
                            default:
                                image = require("../../images/droneOrange.png");
                        }
                    }

                    let registersWithGPS = register.filter((point: any) => point.droneLocation && point.droneLocation.lat !== 0 && point.droneLocation.lon !== 0);

                    if (registersWithGPS.length > 0) {
                        hasGPS = true;
                    }

                    registersLayer.addLayer(
                        new L.Polyline(
                            registersWithGPS.map((point: any) => [point.droneLocation.lat, point.droneLocation.lon])
                        )
                    );
                    if (!hasGPS) {
                        drawNoGPSRadius(registersLayer, register[0], registersStore.activeRegisters[index].center);
                    } else {
                        for (var i = 0; i < register.length; i++) {

                            if (register[i].droneLocation) {
                                registersLayer.addLayer(
                                    new L.CircleMarker([register[i].droneLocation.lat, register[i].droneLocation.lon],
                                        {
                                            radius: 5,
                                            color: "white",
                                            fillColor: "white"
                                        }).bindTooltip(
                                            `
                                <Table responsive>
                                    <tr>
                                        <td style="font-weight: bold; border-top: none">`+ t("drone.identifier") + `</td>
                                        <td style="border-top: none">`+ [registersStore.activeRegisters[index] && registersStore.activeRegisters[index].drone ? registersStore.activeRegisters[index].drone.sn : "-"] + `</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">`+ t("detection.drone-location") + `</td>
                                        <td style="border-top: none">`+ [register[i].droneLocation && register[i].droneLocation.lat ? register[i].droneLocation.lat.toFixed(4) : 0, register[i].droneLocation && register[i].droneLocation.lon ? register[i].droneLocation.lon.toFixed(4) : 0] + `</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">`+ t("detection.height") + `</td>
                                        <td>`+ register[i].fHeight + `m</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; border-top: none">`+ t("detection.pilot-location") + `</td>
                                        <td style="border-top: none">`+ [register[i].driverLocation && register[i].driverLocation.lat ? register[i].driverLocation.lat.toFixed(4) : 0, register[i].driverLocation && register[i].driverLocation.lon ? register[i].driverLocation.lon.toFixed(4) : 0] + `</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; border-top: none">`+ t("detection.home-location") + `</td>
                                        <td style="border-top: none">`+ [register[i].homeLocation && register[i].homeLocation.lat ? register[i].homeLocation.lat.toFixed(4) : 0, register[i].homeLocation && register[i].homeLocation.lon ? register[i].homeLocation.lon.toFixed(4) : 0] + `</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; border-top: none">`+ t("detection.timestamp") + `</td>
                                        <td style="border-top: none">`+ new Date(register[i].time).toLocaleString() + `</td>
                                    </tr>
                                </Table>
                                `,
                                            {
                                                sticky: true,
                                                direction: "top"
                                            },
                                        )
                                );
                            }

                            if (i === 0 && register[i].homeLocation && register[i].homeLocation.lat !== 0 && register[i].homeLocation.lon !== 0) registersLayer.addLayer(
                                new L.Marker([register[i].homeLocation.lat, register[i].homeLocation.lon], {
                                    icon: new L.Icon({
                                        iconUrl: require("../../images/home.png"),
                                        iconAnchor: [55 / 2, 0],
                                        iconSize: [55, 55]
                                    })
                                })

                            );
                            if (i === 0 && register[i].driverLocation && register[i].driverLocation.lat !== 0 && register[i].driverLocation.lon !== 0) registersLayer.addLayer(
                                new L.Marker([register[i].driverLocation.lat, register[i].driverLocation.lon], {
                                    icon: new L.Icon({
                                        iconUrl: require("../../images/rc.png"),
                                        iconAnchor: [55 / 2, 0],
                                        iconSize: [55, 55]
                                    })
                                })

                            );

                            if (i === register.length - 1 && register[i].droneLocation) registersLayer.addLayer(
                                new L.Marker([register[i].droneLocation.lat, register[i].droneLocation.lon], {
                                    icon: new L.DivIcon({
                                        html: '<div style="transform: rotate(' + register[i].rotation + 'deg); background-color: transparent;"><img style="width:100%; height:100%;" src="' + image + '"></div>',
                                        iconAnchor: [45 / 2, 0],
                                        iconSize: [45, 45]
                                    })
                                }).bindTooltip(
                                    `
                                <Table responsive>
                                <tr>
                                    <td style="font-weight: bold; border-top: none">`+ t("drone.identifier") + `</td>
                                    <td style="border-top: none">`+ [registersStore.activeRegisters[index] && registersStore.activeRegisters[index].drone ? registersStore.activeRegisters[index].drone.sn : "-"] + `</td>
                                </tr>
                                <tr>
                                    <td style="font-weight: bold;">`+ t("detection.drone-location") + `</td>
                                    <td style="border-top: none">`+ [register[i].droneLocation && register[i].droneLocation.lat ? register[i].droneLocation.lat.toFixed(4) : 0, register[i].droneLocation && register[i].droneLocation.lon ? register[i].droneLocation.lon.toFixed(4) : 0] + `</td>
                                </tr>
                                <tr>
                                    <td style="font-weight: bold">`+ t("detection.height") + `</td>
                                    <td>`+ register[i].fHeight + `m</td>
                                </tr>
                                <tr>
                                    <td style="font-weight: bold; border-top: none">`+ t("detection.pilot-location") + `</td>
                                    <td style="border-top: none">`+ [register[i].driverLocation && register[i].driverLocation.lat ? register[i].driverLocation.lat.toFixed(4) : 0, register[i].driverLocation && register[i].driverLocation.lon ? register[i].driverLocation.lon.toFixed(4) : 0] + `</td>
                                </tr>
                                <tr>
                                    <td style="font-weight: bold; border-top: none">`+ t("detection.home-location") + `</td>
                                    <td style="border-top: none">`+ [register[i].homeLocation && register[i].homeLocation.lat ? register[i].homeLocation.lat.toFixed(4) : 0, register[i].homeLocation && register[i].homeLocation.lon ? register[i].homeLocation.lon.toFixed(4) : 0] + `</td>
                                </tr>
                                <tr>
                                    <td style="font-weight: bold; border-top: none">`+ t("detection.timestamp") + `</td>
                                    <td style="border-top: none">`+ new Date(register[i].time).toLocaleString() + `</td>
                                </tr>
                            </Table>
                        `,
                                    {
                                        sticky: true,
                                        direction: "top"
                                    },
                                )
                            );
                        }
                    }
                }
            }
        });

        return registersLayer;
    }

    function getLastDriverLocation(detectionLog: any) {
        for (let i = detectionLog.length - 1; i >= 0; i--) {
            const detection = detectionLog[i];

            if (detection.driverLocation != null && detection.driverLocation.lat && detection.driverLocation.lon) return detection.driverLocation;
        }

        return null;
    }

    function getLastHomeLocation(detectionLog: any) {
        for (let i = detectionLog.length - 1; i >= 0; i--) {
            const detection = detectionLog[i];

            if (detection.homeLocation != null && detection.homeLocation.lat && detection.homeLocation.lon) return detection.homeLocation;
        }

        return null;
    }

    const createRealtimeLayer = () => {
        var detectors = detectorsStore.detectors.map((detector: any) => toJS(detector));
        var detections = JSON.parse(JSON.stringify(realtimeStore.activeDetections));
        let realtimeLayer: any = new L.LayerGroup();
        realtimeLayer.isRealtimeLayer = true;

        Object.keys(detections).map(function (key, index) {
            let detection = detections[key]
            var image = require("../../images/droneOrange.png")
            if (detection?.[1]?.[index]) {

                switch (detection[1][index].drone.type) {
                    case "FRIEND":
                    case "AUTHORIZED":
                        image = require("../../images/droneGreen.png")
                        break;

                    case "STOLEN":
                        image = require("../../images/droneStolen.png")
                        break;
                    
                    case "FAKE":
                    case "NEUTRALIZED":
                        image = require("../../images/droneFake.png");
                        break;
                    
                    case "ENEMY":
                        image = require("../../images/droneRed.png");
                        break;
                    
                    case "NOTIFY":
                        image = require("../../images/droneBlue.png");
                        break;

                    default:
                        image = require("../../images/droneOrange.png");
                }
            }
            //let image = detection[1][index].type === "FAKE" ? require("../../images/droneFakeArr.png") : detection[1][index].type === "FRIEND" ? require("../../images/droneGreenArr.png") : detection[1][index].type === "ENEMY" ? require("../../images/droneRedArr.png") : detection[1][index].type === "AUTHORIZED" ? require("../../images/droneBlueArr.png") : require("../../images/droneOrangeArr.png");

            realtimeLayer.addLayer(
                new L.Polyline(
                    detection[1].slice(-30).filter((point: any) => point.droneLocation && point.droneLocation.lat && point.droneLocation.lon).map((point: any) => [point.droneLocation.lat, point.droneLocation.lon]),
                    { color: realtimeStore.selectedColors[detection[1][0].drone.sn] }
                )
            );
            for (var i = Math.max(0, detection[1].length - 30); i < detection[1].length; i++) {
                // Punto inicial o intermedio y con coordenadas DISTINTAS a (0.0000, 0.0000)

                if (detection[1][i].droneLocation && detection[1][i].droneLocation.lat && detection[1][i].droneLocation.lon) {
                    realtimeLayer.addLayer(
                        new L.CircleMarker(
                            [detection[1][i].droneLocation.lat, detection[1][i].droneLocation.lon],
                            {
                                radius: 5,
                                color: "white",
                                fillColor: "white"
                            }
                        ).bindTooltip(
                            `
                    <Table responsive>
                        <tr>
                            <td style="font-weight: bold; border-top: none">`+ t("drone.identifier") + `</td>
                            <td style="border-top: none">`+ [detection[1][i] && detection[1][i].drone ? detection[1][i].drone.sn : "-"] + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">`+ t("detection.drone-location") + `</td>
                            <td style="border-top: none">`+ [detection[1][i].droneLocation && detection[1][i].droneLocation.lat ? detection[1][i].droneLocation.lat.toFixed(4) : 0, detection[1][i].droneLocation && detection[1][i].droneLocation.lon ? detection[1][i].droneLocation.lon.toFixed(4) : 0] + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold">`+ t("detection.height") + `</td>
                            <td>`+ detection[1][i].fHeight + `m</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold; border-top: none">`+ t("detection.pilot-location") + `</td>
                            <td style="border-top: none">`+ [detection[1][i].driverLocation && detection[1][i].driverLocation.lat ? detection[1][i].driverLocation.lat.toFixed(4) : 0, detection[1][i].driverLocation && detection[1][i].driverLocation.lon ? detection[1][i].driverLocation.lon.toFixed(4) : 0] + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold; border-top: none">`+ t("detection.home-location") + `</td>
                            <td style="border-top: none">`+ [detection[1][i].homeLocation && detection[1][i].homeLocation.lat ? detection[1][i].homeLocation.lat.toFixed(4) : 0, detection[1][i].homeLocation && detection[1][i].homeLocation.lon ? detection[1][i].homeLocation.lon.toFixed(4) : 0] + `</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold; border-top: none">`+ t("detection.timestamp") + `</td>
                            <td style="border-top: none">`+ new Date(detection[1][i].time).toLocaleString() + `</td>
                        </tr>
                    </Table>
                    `,
                            {
                                sticky: true,
                                direction: "top"
                            },
                        )
                    );
                }
                // Punto inicial o intermedio y con coordenadas IGUALES a (0.0000, 0.0000)

                //Punto final
                if (i === detection[1].length - 1) {
                    let driverLocation = getLastDriverLocation(detection[1]);
                    let homeLocation = getLastHomeLocation(detection[1]);

                    if (driverLocation && driverLocation.lat && driverLocation.lon) {
                        realtimeLayer.addLayer(
                            new L.Marker([driverLocation.lat, driverLocation.lon], {
                                icon: new L.Icon({
                                    iconUrl: require("../../images/rc.png"),
                                    iconAnchor: [55 / 2, 0],
                                    iconSize: [55, 55]
                                })
                            }).bindTooltip(
                                `
                        <Table responsive>
                            <tr>
                                <td style="font-weight: bold; border-top: none">`+ t("drone.identifier") + `</td>
                                <td style="border-top: none">`+ [detection[1][i] && detection[1][i].drone ? detection[1][i].drone.sn : "-"] + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">`+ t("detection.pilot-location") + `</td>
                                <td style="border-top: none">`+ [driverLocation.lat.toFixed(4), driverLocation.lon.toFixed(4)] + `</td>
                            </tr>
                        </Table>
                        `,
                                {
                                    sticky: true,
                                    direction: "top"
                                },
                            ));
                    }

                    if (homeLocation && homeLocation.lat && homeLocation.lon) {
                        realtimeLayer.addLayer(
                            new L.Marker([homeLocation.lat, homeLocation.lon], {
                                icon: new L.Icon({
                                    iconUrl: require("../../images/home.png"),
                                    iconAnchor: [55 / 2, 0],
                                    iconSize: [55, 55]
                                })
                            }).bindTooltip(
                                `
                        <Table responsive>
                            <tr>
                                <td style="font-weight: bold; border-top: none">`+ t("drone.identifier") + `</td>
                                <td style="border-top: none">`+ [detection[1][i] && detection[1][i].drone ? detection[1][i].drone.sn : "-"] + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">`+ t("detection.home-location") + `</td>
                                <td style="border-top: none">`+ [homeLocation.lat.toFixed(4), homeLocation.lon.toFixed(4)] + `</td>
                            </tr>
                        </Table>
                        `,
                                {
                                    sticky: true,
                                    direction: "top"
                                },
                            ));
                    }
                    // Punto final y con coordenadas DISTINTAS a (0.0000, 0.0000)
                    if (detection[1][i].droneLocation && detection[1][i].droneLocation.lat && detection[1][i].droneLocation.lon) {
                        realtimeLayer.addLayer(
                            new L.Marker([detection[1][i].droneLocation.lat, detection[1][i].droneLocation.lon], {
                                icon: new L.DivIcon({
                                    html: '<div style="transform: rotate(' + detection[1][i].rotation + 'deg); background-color: transparent;"><img style="width:100%; height:100%;" src="' + image + '"></div>',
                                    iconAnchor: [45 / 2, 0],
                                    iconSize: [45, 45],
                                    className: detection[1][i].drone.type === "FAKE" ? "blinking" : ""
                                })
                            }).bindTooltip(
                                `
                <Table responsive>
                    <tr>
                        <td style="font-weight: bold; border-top: none">`+ t("drone.identifier") + `</td>
                        <td style="border-top: none">`+ [detection[1][i] && detection[1][i].drone ? detection[1][i].drone.sn : "-"] + `</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">`+ t("detection.drone-location") + `</td>
                        <td style="border-top: none">`+ [detection[1][i].droneLocation && detection[1][i].droneLocation.lat ? detection[1][i].droneLocation.lat.toFixed(4) : 0, detection[1][i].droneLocation && detection[1][i].droneLocation.lon ? detection[1][i].droneLocation.lon.toFixed(4) : 0] + `</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">`+ t("detection.height") + `</td>
                        <td>`+ detection[1][i].fHeight + `m</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; border-top: none">`+ t("detection.pilot-location") + `</td>
                        <td style="border-top: none">`+ [driverLocation && driverLocation.lat ? driverLocation.lat.toFixed(4) : 0, driverLocation && driverLocation.lon ? driverLocation.lon.toFixed(4) : 0] + `</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; border-top: none">`+ t("detection.home-location") + `</td>
                        <td style="border-top: none">`+ [homeLocation && homeLocation.lat ? homeLocation.lat.toFixed(4) : 0, homeLocation && homeLocation.lon ? homeLocation.lon.toFixed(4) : 0] + `</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; border-top: none">`+ t("detection.timestamp") + `</td>
                        <td style="border-top: none">`+ new Date(detection[1][i].time).toLocaleString() + `</td>
                    </tr>
                </Table>
                `,
                                {
                                    sticky: true,
                                    direction: "top"
                                },
                            ));
                    } else {// Punto final y con coordenadas IGUALES a (0.0000, 0.0000)
                        drawNoGPSRadius(realtimeLayer, detection[1][i]);
                    }

                }
            }
        });
        return realtimeLayer;
    }

    const buildMap = () => {
        let defaultStyle = localStorage.getItem("mapStyle") !== undefined && localStorage.getItem("mapStyle") !== null && localStorage.getItem("mapStyle") !== "" ? localStorage.getItem("mapStyle") : "ayrdots/clftcrpwk00mz01mxaf65zz1h";
        let map = new L.Map('map', {
            zoomSnap: 0.5,
            zoomDelta: 0.5
        });

        let tiles = new L.TileLayer(
            "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", {
            accessToken: "pk.eyJ1IjoiYXlyZG90cyIsImEiOiJjbGZ0Ymd6NWMwZWhlM2ZyeXc1b3BjdHV1In0.xWkPouCVco57LUcC-1OvQQ",
            id: defaultStyle!,
            tileSize: 512,
            zoomOffset: -1,
            maxNativeZoom: 24,
            maxZoom: 24,
        }).addTo(map);
        map.on('moveend', () => {
            localStorage.setItem("mapCenterLat", map.getCenter().lat.toString());
            localStorage.setItem("mapCenterLon", map.getCenter().lng.toString());
            localStorage.setItem("mapZoom", map.getZoom().toString());
        });
        map.setView(localStorage.getItem('mapCenterLat') ? [Number(localStorage.getItem('mapCenterLat')), Number(localStorage.getItem('mapCenterLon'))] : [39.538391691513816, -0.06510968257644745], localStorage.getItem('mapZoom') ? Number(localStorage.getItem('mapZoom')) : 7);
        setCurrentStyle(defaultStyle);

        setLeafletMap(map);
        setTileLayer(tiles);
    }

    const changeSelectedStyle = (newStyle: string) => {
        leafletMap.removeLayer(tileLayer);
        let tiles = new L.TileLayer(
            "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", {
            accessToken: "pk.eyJ1IjoiYXlyZG90cyIsImEiOiJjbGZ0Ymd6NWMwZWhlM2ZyeXc1b3BjdHV1In0.xWkPouCVco57LUcC-1OvQQ",
            id: newStyle,
            tileSize: 512,
            zoomOffset: -1,
            maxNativeZoom: 24,
            maxZoom: 24,
        }).addTo(leafletMap);
        setTileLayer(tiles);
        setCurrentStyle(newStyle);
        localStorage.setItem("mapStyle", newStyle);
    }

    const changeValueOfCheckboxLayers = (index: number) => {
        var activeLayersNow = [];
        let layersIds = "";
        for (var i = 0; i < activeLayers.length; i++) {
            activeLayersNow.push(activeLayers[i]);
            if (i === index) {
                activeLayersNow[i] = !activeLayersNow[i];
            }
            layersIds += activeLayersNow[i] ? "1" : "0"
        }
        localStorage.setItem("mapLayers", layersIds);
        setActiveLayers(activeLayersNow);
    }

    const buildMiniMaps = () => {
        let mapStyles = ["ayrdots/clftcrpwk00mz01mxaf65zz1h", "ayrdots/clftcte82009l01t6ozt6p5vw", "ayrdots/clftd127y000o01qkf39u87a2", "ayrdots/clftctsyl000301nxfl2bherx"];
        var mapTiles: any = [];
        mapStyles.forEach((mapStyle) => {
            mapTiles.push(
                <OverlayTrigger
                    key={mapStyle}
                    placement='right'
                    overlay={
                        <Tooltip id={mapStyle}>
                            {t("widgets.map-style.btn-change-style-hover")}
                        </Tooltip>
                    }
                >
                    <ListGroup.Item active={currentStyle === mapStyle} onClick={() => changeSelectedStyle(mapStyle)} key={mapStyle} style={{ cursor: "pointer" }}>
                        {mapStyle === "ayrdots/clftcrpwk00mz01mxaf65zz1h" ? t("widgets.map-style.style-streets") : mapStyle === "ayrdots/clftcte82009l01t6ozt6p5vw" ? t("widgets.map-style.style-light") : mapStyle === "ayrdots/clftd127y000o01qkf39u87a2" ? t("widgets.map-style.style-dark") : t("widgets.map-style.style-satellite")}
                    </ListGroup.Item>
                </OverlayTrigger>
            );
        });
        return mapTiles;
    }

    const buildLayersCheckboxes = () => {
        let checkBoxes = [t("widgets.map-layers.detectors"), t("widgets.map-layers.inhibitors"), t("widgets.map-layers.zones")];
        if (props.type === "realtime") checkBoxes.push(t("widgets.map-layers.aircrafts"));
        else if (props.type === "registers") checkBoxes.push(t("widgets.map-layers.heat-map"));
        var checkBoxesToRender: any = [];
        checkBoxes.forEach((name, index) => {
            if ((index === 0 && props.showDetectors) || (index === 1 && props.showInhibitors) || (index === 2 && props.showZones) || (index === 3))
                checkBoxesToRender.push(
                    <OverlayTrigger
                        key={name}
                        placement='right'
                        overlay={
                            <Tooltip id={name}>
                                {t("widgets.map-layers.btn-hover")}
                            </Tooltip>
                        }
                    >
                        <ListGroup.Item onClick={() => changeValueOfCheckboxLayers(index)} style={{ cursor: "pointer" }}>
                            <Form.Check
                                type="switch"
                                label={name}
                                checked={activeLayers[index]}
                                style={{ cursor: "pointer" }}
                            />
                        </ListGroup.Item>
                    </OverlayTrigger>
                );
        });
        return checkBoxesToRender;
    }

    const buildAudioToogler = () => {
        return (
            <OverlayTrigger
                key={'turnOffAlarm'}
                placement='right'
                overlay={
                    <Tooltip id='turnOffAlarm'>
                        {t("widgets.alarm.btn-hover")}
                    </Tooltip>
                }
            >

                <ListGroup.Item style={{ marginBottom: "10px", cursor: "pointer" }} onClick={() => { realtimeStore.stopAlarmSound(); }}>
                    <FontAwesomeIcon icon={faVolumeMute} style={{ marginRight: "10px", color: "red", transform: "scale(1.2)" }} /> {t("widgets.alarm.content")}
                </ListGroup.Item>
            </OverlayTrigger>
        );
    }

    const buildDetectorsContainer = () => {
        return props.fourthChild
    }

    const buildInhibitorContainer = () => {
        return inhibitorsStore.selectedInhibitor ? <InhibitorComp setShowAutoInhibitionRadius={setShowAutoInhibitionRadius} setAutoInhibitionRadius={setAutoInhibitionRadius} activeDetections={Object.values(realtimeStore.activeDetections).map((detection: any) => { return detection[1][0].drone.sn })} autoTrack={autoTrack} setAutoTrack={setAutoTrack} trackedDrone={trackedDrone} setTrackedDrone={setTrackedDrone} /> : <div style={{ backgroundColor: "white", width: "100%", height: "0" }} />;
    }

    useEffect(() => {
        buildMap();
    }, []);

    if ((leafletMap) && zonesStore.status === ZonesStatus.Succeded && props.showZones) {
        if (zonesLayer === null) {
            let zonesLayer = createZonesLayer();
            zonesLayer.addTo(leafletMap);
            setZonesLayer(zonesLayer);
        } else {
            if (!activeLayers[2]) {
                leafletMap.removeLayer(zonesLayer);
            } else {
                leafletMap.addLayer(zonesLayer);
            }
        }
    }

    if ((leafletMap) && props.showFlights && props.type === "realtime") {
        if (!activeLayers[3] && aircraftsLayer) {
            leafletMap.removeLayer(aircraftsLayer);
        }
    }

    if ((leafletMap) && detectorsStore.status === DetectorsStatus.Loaded && props.showDetectors) {
        if (!activeLayers[0] && detectorsLayer !== null) {
            leafletMap.removeLayer(detectorsLayer);
        }
        else {
            if (detectorsLayer === null || realtimeStore.updateDetectors) {
                let detectorsLayerNow = createDetectorsLayer();
                detectorsLayerNow.addTo(leafletMap);
                setDetectorsLayer(detectorsLayerNow);
            }
            if (detectorsLayer !== null) {
                if (realtimeStore.updateDetectors) {
                    leafletMap.removeLayer(detectorsLayer);
                }
                else {
                    leafletMap.addLayer(detectorsLayer);
                }
            }
            realtimeStore.updateDetectors = false;
        }
    }

    if ((leafletMap) && inhibitorsStore.status === InhibitorsStatus.Loaded && props.showInhibitors) {
        if (!activeLayers[1] && inhibitorsLayer !== null) {
            leafletMap.removeLayer(inhibitorsLayer);
        }
        else {
            if (inhibitorsLayer === null || realtimeStore.updateInhibitors) {
                let inhibitorsLayerNow = createInhibitorsLayer();
                inhibitorsLayerNow.addTo(leafletMap);
                setInhibitorsLayer(inhibitorsLayerNow);
            }
            if (inhibitorsLayer !== null) {
                if (realtimeStore.updateInhibitors) {
                    leafletMap.removeLayer(inhibitorsLayer);
                }
                else {
                    leafletMap.addLayer(inhibitorsLayer);
                }
            }
            realtimeStore.updateInhibitors = false;
        }
    }


    useEffect(() => {
        if (!registersStore.showHeatmap && heatmapRegistersLayer) {
            leafletMap.removeLayer(heatmapRegistersLayer);
            setHeatmapRegistersLayer(null);
        }
    }, [registersStore.showHeatmap])

    if (leafletMap && props.type === "registers") {

        if (activeLayers[3]) {
            var cfg = {
                "radius": 25,
                "maxOpacity": .6,
                "scaleRadius": false,
                "useLocalExtrema": true,
                latField: 'lat',
                lngField: 'lon',
                valueField: 'count'
            };
            if (heatmapRegistersLayer === null) {
                let registers = { ...registersStore.registers };
                let registersData = []

                for (const [key, value] of Object.entries(registers)) {
                    for (var routePoint of registers[key].route) {
                        if (!routePoint || !routePoint["droneLocation"]) continue

                        if (routePoint.droneLocation.lat && routePoint.droneLocation.lat != 0 && routePoint.droneLocation.lon && routePoint.droneLocation.lon != 0)
                            registersData.push({ lat: routePoint.droneLocation.lat, lon: routePoint.droneLocation.lon, count: 1 });
                    }
                }

                for (const [key, value] of Object.entries(registers)) {
                    for (var routePoint of registers[key].route) {
                        if (!routePoint || !routePoint["droneLocation"]) continue

                        if (routePoint.droneLocation.lat && routePoint.droneLocation.lat != 0 && routePoint.droneLocation.lon && routePoint.droneLocation.lon != 0)
                            registersData.push({ lat: routePoint.droneLocation.lat, lon: routePoint.droneLocation.lon, count: 1 });
                    }
                }

                if (registersData && registersData.length > 0) {
                    var heatmapLayer = new HeatmapOverlay(cfg);
                    heatmapLayer.setData({ data: registersData });
                    leafletMap.addLayer(heatmapLayer)
                    setHeatmapRegistersLayer(heatmapLayer);
                }
            } else if (registersStore.registers.length !== previousRegistersLength) {
                leafletMap.removeLayer(heatmapRegistersLayer);
                let registers = { ...registersStore.registers };
                let registersData = []
                for (const [key, value] of Object.entries(registers)) {
                    for (var routePoint of registers[key].route) {
                        if (!routePoint || !routePoint["droneLocation"]) continue


                        if (routePoint.droneLocation.lat && routePoint.droneLocation.lat != 0 && routePoint.droneLocation.lon && routePoint.droneLocation.lon != 0)
                            registersData.push({ lat: routePoint.droneLocation.lat, lon: routePoint.droneLocation.lon, count: 1 });
                    }
                }
                if (registersData && registersData.length > 0) {
                    var heatmapLayer = new HeatmapOverlay(cfg);
                    heatmapLayer.setData({ data: registersData });
                    leafletMap.addLayer(heatmapLayer)
                    setHeatmapRegistersLayer(heatmapLayer);
                }
                setPreviousRegistersLength(Object.keys(registersStore.registers).length);
            }
        } else {
            if (heatmapRegistersLayer) {
                leafletMap.removeLayer(heatmapRegistersLayer);
                setHeatmapRegistersLayer(null);
            }
        }


        if (registersLayer === null) {
            let registersLayerNow = createRegistersLayer();
            registersLayerNow.addTo(leafletMap);
            setRegistersLayer(registersLayerNow);
        } else if (registersStore.activeRegisters.length !== previousActiveRegistersLength) {
            let registersLayerNow = createRegistersLayer();
            leafletMap.removeLayer(registersLayer);
            registersLayerNow.addTo(leafletMap);
            setRegistersLayer(registersLayerNow);
            setPreviousActiveRegistersLength(registersStore.activeRegisters.length);
        }
    }

    useEffect(()=>{
        let timeout: NodeJS.Timeout |null; // Declare timeout variable outside the conditional
    
        if (leafletMap && props.type === "realtime") {
            if (realtimeLayer === null) {
                let realtimeLayerNow = createRealtimeLayer();
                realtimeLayerNow.addTo(leafletMap);
                setRealtimeLayer(realtimeLayerNow);
            }
    
            timeout = setInterval(()=>{
                let realtimeLayerNow = createRealtimeLayer();
                leafletMap?.eachLayer(function(layer: any) {
                    if (layer !== tileLayer && layer?.isRealtimeLayer) {
                        leafletMap?.removeLayer(layer);
                    }
                });
                realtimeLayerNow.addTo(leafletMap);
                setRealtimeLayer(realtimeLayerNow);
            }, 1500)
        }
    
        return () => {
            if (timeout) {
                clearInterval(timeout); // Clear the interval when the component is unmounted or when dependencies change
            }
        };
    }, [leafletMap, props.type]);
    

    if (leafletMap && props.type === "realtime" && realtimeStore.needsToRedraw) {
        // if (realtimeLayer === null) {
        //     let realtimeLayerNow = createRealtimeLayer();
        //     realtimeLayerNow.addTo(leafletMap);
        //     setRealtimeLayer(realtimeLayerNow);
        // } else if (toJS(realtimeStore.activeDetections) !== previousRealtimeStatus) {
        //     let realtimeLayerNow = createRealtimeLayer();
        //     let count = 0;
        //     leafletMap.eachLayer(() => count++);
        //     console.log('Total number of layers:', count);
        //     leafletMap.removeLayer(realtimeLayer);
        //     leafletMap.eachLayer(function(layer: any) {
        //         leafletMap.removeLayer(layer);
        //     });
        //     realtimeLayerNow.addTo(leafletMap);
        //     setRealtimeLayer(realtimeLayerNow);
        //     setPreviousRealtimeStatus(toJS(realtimeStore.activeDetections));

        //     realtimeStore.needsToRedraw = false;
        // }
    }

    if (leafletMap && mapAnimationStore.focusStatus === FocusStatus.Focused) {
        leafletMap.fitBounds(mapAnimationStore.fitBounds, {
            maxZoom: 19,
            paddingTopLeft: [70, 70],
            paddingBottomRight: [750, 70]
        });
    }

    if (props.type === "realtime" && realtimeStore.alarms.length > 0) {
        for (var j = 0; j < realtimeStore.alarms.length; j++) {
            if (realtimeStore.alarms[j].active) {
                let alarm = realtimeStore.alarms[j];
                alarms.push(
                    <Toast show={true} onClose={() => realtimeStore.deactivateAlarm(alarm.uid)}>
                        <Toast.Header>
                            <strong className="mr-auto">{alarm.title}</strong>
                            <small>{alarm.time.toISOString().substring(11, 16)}</small>
                        </Toast.Header>
                        <Toast.Body style={{ backgroundColor: alarm.type === "ALERT" ? "rgba(255, 221, 51, 0.5)" : alarm.type === "ALARM" ? "rgba(255,87,51,0.5)" : alarm.type === "InhibChangeNOOK" ? "rgba(255,87,51,0.5)" : "rgba(70,255,51,0.5)" }}>{alarm.body}</Toast.Body>
                    </Toast>
                );
            }
        }
    }



    function handleScroll(e: any) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    }


    return (
        <>
            <DownloadingDocumentCard
                visible={props.showDownloadingDoc}
            >
                <div style={{ display: "flex", flexDirection: "column", width: "85%" }}>
                    <b style={{ fontSize: "1.2em" }}>{t("widgets.download.header")}</b>
                    <span>{t("widgets.download.content")}</span>
                </div>
                <div style={{ height: "100%", width: "15%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Spinner animation="grow" variant="primary" />
                </div>
            </DownloadingDocumentCard>
            {
                Object.entries(realtimeStore.inhibitingInhibitors).map((activeInhibition: any, index: number) => {
                    let topGapString = (81 + (10 * index)).toString() + 'px';
                    var activeModulesString = '';
                    if (activeInhibition[1].GPS) {
                        activeModulesString = 'GPS';
                        if (activeInhibition[1].control) {
                            activeModulesString += ' - CONTROL';
                        }
                    } else {
                        activeModulesString = "CONTROL";
                    }
                    return (
                        <Alert show variant='danger' style={{ position: "absolute", top: topGapString, left: "45px", margin: "10px", zIndex: 700 }}>
                            {t("widgets.inhibition-alert.header1")} ({<span style={{ fontWeight: "bolder" }}> {activeModulesString} </span>}) {t("widgets.inhibition-alert.header2")} {<span style={{ fontWeight: "bolder" }}> {activeInhibition[1].name} </span>} {t("widgets.inhibition-alert.header3")} {<span style={{ fontWeight: "bolder" }}> {activeInhibition[1].time} </span>}.
                        </Alert>
                    );
                })
            }
            <div style={{ position: "absolute", top: "90px", left: "50px", zIndex: 510 }}>
                {buildDetectorsContainer()}
            </div>
            {
                websocketStore.falsePositives > 0 && 
                <div className="falsePositivesCard" onClick={() => { activePageStore.changeActivePage(PageIndex.Realtime) }} style={{ margin: "4.5rem 0 0 0", cursor:"pointer", padding:"1rem", left: "0", position: "absolute", borderRadius: "0px", height: "fit-content", marginLeft: "10px", zIndex: 500, animation: "activeDetectionOrange 1s ease-in-out infinite alternate !important"}}>
                            <div style={{ borderRadius: "7px", padding: "0px !important", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{width: "65px", height:"65px"}}>
                                    <img src={require('../../images/imagotipo_asdt_blanco.png')} style={{width:"100%", height: "100%"}} />
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                    <div style={{display: "flex", marginLeft: "1rem", flexDirection: "column"}}>
                                        <span style={{ fontSize: "large", color: "white", fontWeight: "bold", whiteSpace: "nowrap" }}>Real-Time Coherence</span>
                                        <div style={{display: "flex", justifyContent: "start", height: "50px", width: "100%", position: "relative", marginTop:"-15px"}}>
                                            <span style={{ fontSize: "40px", scale: "0.9", color: "white", fontWeight: "bold", whiteSpace: "nowrap" }}>{`${websocketStore.falsePositives}`}</span>
                                            <span style={{ color: "white", scale: "0.8", fontWeight: "bold", textAlign: "left", marginTop: "12px", marginLeft: "-5px" }}>{`FALSE`}<br className='custom-br'/>{`POSITIVES`}</span>
                                        </div>

                                        </div>
                                    </div>
                                </div>
                                
                            </div>
            }
            <div style={{
                position: "absolute", bottom: "220px", left: "20px", backgroundColor: "#ffff",
                display: usersdataStore.user && usersdataStore.user.email === "pruebas-ses@asdt.eu" ? "block" : "none",
                padding: "1rem",
                width: "380px",
                borderRadius: "5px", zIndex: 510
            }}>
                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label={toggle ? "Modo Local" : "SENDES Ecosystem"}
                        onChange={() => {
                            setToggle(!toggle);
                        }}
                    />
                </Form>
            </div>
            <div style={{ position: "absolute", top: "81px", right: "0px", width: "33vw", maxWidth: "500px", minWidth: "450px", overflowY: "auto", maxHeight: "calc(100vh - 81px)", zIndex: 510 }} onScroll={(e: any) => { handleScroll(e) }}>
                {props.child}
            </div>
            <div style={{ position: "absolute", display: "flex", bottom: "10px", left: "10px", alignItems: "flex-end" }}>
                <div style={{ zIndex: 510, marginLeft: "10px" }}>
                    <ListGroup as="ul">
                        {buildMiniMaps()}

                    </ListGroup>
                </div>
                <div style={{ zIndex: 510, marginLeft: "10px" }}>
                    <Form>
                        {
                            props.showAudioToggle && realtimeStore.isAlarmFired ?
                                <ListGroup as="ul">
                                    {buildAudioToogler()}
                                </ListGroup> :
                                null
                        }
                        <ListGroup as="ul">
                            {buildLayersCheckboxes()}
                        </ListGroup>
                    </Form>
                </div>
                {
                    Object.keys(realtimeStore.activeDetections).length > 0 && props.type === "registers" ?
                        <Card style={{ borderRadius: "7px", height: "fit-content", marginLeft: "10px", zIndex: 520, bottom: inhibitorsStore.selectedInhibitor && props.type !== "registers" ? props.showAudioToggle && realtimeStore.isAlarmFired ? "280px" : "220px" : "0px", animation: "activeDetection 1s ease-in-out infinite alternate", cursor: "pointer" }} onClick={() => { activePageStore.changeActivePage(PageIndex.Realtime) }}>
                            <Card.Body style={{ borderRadius: "7px", padding: "5px 20px !important", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <span style={{ fontSize: "x-large", color: "white", fontWeight: "bold" }}>{t("widgets.detection.header")}</span>
                            </Card.Body>
                        </Card>
                        :
                        null
                }
                
                
                {
                    props.type === "realtime" && usersdataStore.user?.role.includes("ADMIN") ?
                        <div className={inhibitorsStore.selectedInhibitor ? "inhibitorCard open" : "inhibitorCard"} style={{ height: inhibitorsStore.selectedInhibitor ? "auto" : "0px" }}>
                            {buildInhibitorContainer()}
                        </div>
                        :
                        null
                }
            </div>
            <div>
                <div id="map" style={{ width: "100%", maxHeight: "calc(100vh - 81px)" }} />
            </div>
            <div style={{ zIndex: 620, position: "absolute", right: "calc(33vw + 10px)", top: 101 }}>
                {alarms}
            </div>
        </>
    );
});

export default TestPage;
