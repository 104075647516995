import React, { useState, useContext } from 'react';
import { DetectorsStoreContext } from '../../stores/detectors.store';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InhibitorsStoreContext } from '../../stores/inhibitors.store';
import { useTranslation } from 'react-i18next';
const inh_port_icon = require("../../images/inh_port_icon.png");
const inh_est_icon = require("../../images/inh_est_icon.png");
const det_port_icon = require("../../images/det_port_icon.png");
const det_est_icon = require("../../images/det_est_icon.png");
const sendes_ecosystem_icon = require("../../images/sendes_ecosystem_icon.png");

const DetectorsComp = observer((props: any) => {

    const [t, i18n] = useTranslation("common");

    const detectorsStore = useContext(DetectorsStoreContext);
    const inhibitorsStore = useContext(InhibitorsStoreContext);

    const [showDevices, setShowDevices] = useState(localStorage.getItem('showDevices') == "true" || false);
    const [brokerConnection, setBrokerConnection] = useState(true);



    return (
        <OverlayTrigger
            key={2}
            placement='bottom'
            overlay={
                <Tooltip id='centerLocation'>
                    {t("widgets.devices.btn-hover")}
                </Tooltip>
            }
        >
            <div className={showDevices ? "detectorsCard open" : "detectorsCard"} onClick={() => { localStorage.setItem("showDevices", (!showDevices).toString()); setShowDevices(!showDevices) }}>
                <div style={{ maxHeight: "130px", display: "flex", flexDirection: "column", padding: "5px", overflow: "hidden", visibility: showDevices ? "visible" : "hidden" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div><img src={det_est_icon} style={{ height: "40px", width: "40px" }} /><span>{t("widgets.devices.fixed-detectors")}<b>{detectorsStore.detectors.filter((detector: any) => detector.isActive && !detector.isMobile && !detector.isFixedMobile).length}/{detectorsStore.detectors.filter((detector: any) => !detector.isMobile && !detector.isFixedMobile).length}</b></span></div>
                        <span style={{ margin: "0 10px" }}>-</span>
                        <div><img src={det_port_icon} style={{ height: "40px", width: "40px" }} /><span>{t("widgets.devices.mobile-detectors")}<b>{detectorsStore.detectors.filter((detector: any) => detector.isActive && (detector.isMobile || detector.isFixedMobile)).length}/{detectorsStore.detectors.filter((detector: any) => (detector.isMobile || detector.isFixedMobile)).length}</b></span></div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div><img src={inh_est_icon} style={{ height: "40px", width: "40px" }} /><span>{t("widgets.devices.fixed-inhibitors")}<b>{inhibitorsStore.inhibitors.filter((inhibitor: any) => inhibitor.isActive && !inhibitor.isMobile && !inhibitor.isFixedMobile).length}/{inhibitorsStore.inhibitors.filter((inhibitor: any) => !inhibitor.isMobile && !inhibitor.isFixedMobile).length}</b></span></div>
                        <span style={{ margin: "0 10px" }}>-</span>
                        <div><img src={inh_port_icon} style={{ height: "40px", width: "40px" }} /><span>{t("widgets.devices.mobile-inhibitors")}<b>{inhibitorsStore.inhibitors.filter((inhibitor: any) => inhibitor.isActive && (inhibitor.isMobile || inhibitor.isFixedMobile)).length}/{inhibitorsStore.inhibitors.filter((inhibitor: any) => (inhibitor.isMobile || inhibitor.isFixedMobile)).length}</b></span></div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img src={sendes_ecosystem_icon} style={{ height: "30px", width: "30px", margin: "0 5px" }} /><span style={{ marginRight: "5px" }}>{t("widgets.devices.sendescore-connection")} </span>
                        {brokerConnection ? <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />}
                    </div>
                </div>
                <div style={{ paddingLeft: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {!showDevices ? <FontAwesomeIcon style={{ right: "25px", position: "absolute" }} icon={faAngleRight} /> : <FontAwesomeIcon style={{ right: "25px", position: "absolute" }} icon={faAngleLeft} />}
                </div>
            </div>
        </OverlayTrigger >
    );
});


export default DetectorsComp;