import React, {
  useContext,
  useState,
  SyntheticEvent,
  FocusEventHandler,
} from "react";

import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  LoginDataStoreContext,
  LoginStatus,
} from "../../../stores/logindata.store";
import Spinner from "react-bootstrap/Spinner";
import { observer } from "mobx-react-lite";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { userLogin, validateLoginOtp } from "../../../api";

const LoginForm = observer(() => {
  const [show2FAModal, setShow2FAModal] = useState(false);

  const [t, i18n] = useTranslation("common");
  const logindataStore = useContext(LoginDataStoreContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [twoFACode, setTwoFACode] = useState("");

  const [twoFAError, setTwoFAError] = useState("");

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      let has2fa;
      if (email && password) {
        has2fa = await logindataStore.login(email, password);
      }

      if (has2fa) {
        setShow2FAModal(true);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handle2FASubmit = async () => {
    // Handle 2FA code submission here
    // ...

    const response = await logindataStore.validate(email, twoFACode);
    setShow2FAModal(false); // Close the modal after submission

    // await logindataStore.login(email, password);
  };

  return (
    <Col style={{ width: "100%" }}>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          controlId="loginFormEmail"
          style={{
            margin: "0rem 0rem 1.25rem 0rem",
          }}
        >
          <Form.Control
            className="input-focus"
            value={email}
            onChange={(v) => setEmail(v.target.value)}
            type="email"
            placeholder={t("pages.login.email-placeholder")}
            style={{
              color: "white",
              border: "1px solid rgb(255, 255, 255)",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              fontSize: "14px",
              padding: "0.5rem 1.25rem",
              width: "100%",
              opacity: "75%",
              borderRadius: "100px",
            }}
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group
          controlId="loginFormPassword"
          style={{
            margin: "0rem 0rem 1.25rem 0rem",
          }}
        >
          <Form.Control
            className="input-focus"
            value={password}
            onChange={(v) => setPassword(v.target.value)}
            type="password"
            placeholder={t("pages.login.pwd-placeholder")}
            style={{
              color: "white",
              border: "1px solid rgb(255, 255, 255)",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              fontSize: "14px",
              padding: "0.5rem 1.25rem",
              opacity: "75%",
              borderRadius: "100px",
            }}
            required
          ></Form.Control>
        </Form.Group>
        {logindataStore.status === LoginStatus.Waiting ? (
          <div className="d-flex justify-content-center">
            <Spinner
              as="span"
              animation="border"
              variant="primary"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <Button
            block
            type="submit"
            style={{
              minWidth: "100px",
              width: "25%",
              borderRadius: "100px",
              backgroundColor: "#2C3F9B",
              borderColor: "white",
              margin: "auto",
              marginTop: "2rem",
              display: "block",
            }}
          >
            {t("pages.login.btn-login")}
          </Button>
        )}
      </Form>

      {/* 2FA Modal */}
      <Modal show={show2FAModal} onHide={() => setShow2FAModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Two-Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>A 2FA code has been sent to your phone number.</p>
          <p style={{ color: "red" }}>{twoFAError}</p>
          <Form.Control
            type="text"
            placeholder="Enter 2FA code"
            value={twoFACode}
            onChange={(e) => setTwoFACode(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handle2FASubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
});

export default LoginForm;
