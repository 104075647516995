import React, { useContext, useState, useEffect } from 'react';


import { MapAnimationsStoreContext, FocusStatus } from '../../../stores/mapanimations.store';
import { ZonesDataStoreContext } from '../../../stores/zonesdata.store';

import { observer } from 'mobx-react-lite';
import L from 'leaflet';
import 'leaflet-draw';
import "leaflet-draw/dist/leaflet.draw.css";
import { toJS } from 'mobx';
import { resetGlobalState } from 'mobx/lib/internal';
import { useTranslation } from 'react-i18next';


const ZonesMap = observer(() => {

    const [t, i18n] = useTranslation("common");

    const mapanimationsStore = useContext(MapAnimationsStoreContext);
    const zonesdataStore = useContext(ZonesDataStoreContext);

    const [leafletMap, setLeafletMap]: any = useState({});
    const [isMapReady, setIsMapReady] = useState(false);

    const [newZone, setNewZone]: any = useState(null);


    var zonesLayerGroup: L.LayerGroup = new L.LayerGroup();

    var drawControl = new L.Control.Draw({
        position: 'topleft',
        draw: {
            polyline: false,
            marker: false,
            rectangle: false,
            circlemarker: false
        }
    });

    useEffect(() => {
        var map = new L.Map('map');
        map.on('load', () => {
            setIsMapReady(true);
        })
        map.setView(localStorage.getItem('mapCenterLat') ? [Number(localStorage.getItem('mapCenterLat')), Number(localStorage.getItem('mapCenterLon'))] : [40.239748, -4.239292], localStorage.getItem('mapZoom') ? Number(localStorage.getItem('mapZoom')) : 6);
        new L.TileLayer(
            "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", {
            accessToken: "pk.eyJ1IjoiYXlyZG90cyIsImEiOiJjbGZ0Ymd6NWMwZWhlM2ZyeXc1b3BjdHV1In0.xWkPouCVco57LUcC-1OvQQ",
            tileSize: 512,
            zoomOffset: -1,
            id: localStorage.getItem("mapStyle") ? localStorage.getItem("mapStyle")! : "ayrdots/clftcrpwk00mz01mxaf65zz1h",
            maxNativeZoom: 24,
            maxZoom: 24,
        }).addTo(map);


        map.addControl(drawControl);
        setLeafletMap(map);

    }, []);

    if (isMapReady) {

        var drawnItems = L.featureGroup();
        leafletMap.addLayer(drawnItems);

        var type: any = { "circle": "CIRCLE", "polygon": "POLYGON" };

        leafletMap.on(L.Draw.Event.CREATED, (event: any) => {
            zonesdataStore.newZoneType = type[event.layerType];
            if (zonesdataStore.newZoneType === "CIRCLE") {
                zonesdataStore.newZone = {
                    center: { lat: event.layer._latlng.lat, lon: event.layer._latlng.lng },
                    radius: event.layer.options.radius
                };
            }
            if (zonesdataStore.newZoneType === "POLYGON") {
                var points = []
                for (var i = 0; i < event.layer._latlngs[0].length; i++) {
                    points.push({ "lat": event.layer._latlngs[0][i].lat, "lon": event.layer._latlngs[0][i].lng });
                }
                zonesdataStore.newZone = { perimeter: points };
            }
        });
    }


    if (isMapReady) {

        if (mapanimationsStore.focusStatus === FocusStatus.Focused) {
            leafletMap.fitBounds(mapanimationsStore.fitBounds);
        }

        leafletMap.eachLayer((layer: any, index: number) => {
            if (!layer._url) layer.remove();
        });

        for (var k = 0; k < zonesdataStore.zones.length; k++) {
            let zone = zonesdataStore.zones[k];
            if (zone.type === "CIRCLE") {
                zonesLayerGroup.addLayer(new L.Circle(
                    [zone.center.lat, zone.center.lon],
                    {
                        radius: zone.radius,
                        color: zone.securityLevel === "ALERT" ? "orange" : zone.securityLevel === "ALARM" ? "red" : "yellow",
                        fill: false,
                        weight: 6
                    }
                ).bindTooltip(
                    `
                    <Table responsive>
                            <tr>
                                <td style="font-weight: bold; border-top: none">`+ t("zone.name") + `</td>
                                <td style="border-top: none">`+ zone.name + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">`+ t("zone.level") + `</td>
                                <td>`+ zone.securityLevel + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">`+ t("zone.center") + `</td>
                                <td>`+ [zone.center.lat, zone.center.lon] + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">`+ t("zone.radius") + `</td>
                                <td>`+ zone.radius + `m</td>
                            </tr>
                        </Table>
    
                    `,
                    {
                        sticky: true,
                        direction: "top"
                    }
                ));
            } else {
                zonesLayerGroup.addLayer(new L.Polygon(
                    zone.perimeter,
                    {
                        color: zone.securityLevel === "ALERT" ? "orange" : zone.securityLevel === "ALARM" ? "red" : "yellow",
                        fill: false,
                        weight: 6
                    }
                ).bindTooltip(
                    `
                    <Table responsive>
                            <tr>
                                <td style="font-weight: bold; border-top: none">`+ t("zone.name") + `</td>
                                <td style="border-top: none">`+ zone.name + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">`+ t("zone.level") + `</td>
                                <td>`+ zone.securityLevel + `</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">`+ t("zone.points") + `</td>
                                <td>`+ zone.perimeter.length.toString() + `</td>
                            </tr>
                        </Table>
    
                    `,
                    {
                        sticky: true,
                        direction: "top"
                    }
                ));
            }
        }

        if (zonesdataStore.newZone) {
            if (zonesdataStore.newZoneType === "CIRCLE") {
                zonesLayerGroup.addLayer(new L.Circle(
                    [zonesdataStore.newZone.center.lat, zonesdataStore.newZone.center.lon],
                    {
                        radius: zonesdataStore.newZone.radius,
                        fillColor: "blue",
                        fill: true,
                    }
                ).bindTooltip(
                    t("pages.zones.map.new-zone"),
                    {
                        sticky: true,
                        direction: "top"
                    }
                ));
            }
            else {
                zonesLayerGroup.addLayer(new L.Polygon(
                    zonesdataStore.newZone.perimeter,
                    {
                        fillColor: "blue",
                        fill: true,
                    }
                ).bindTooltip(
                    t("pages.zones.map.new-zone"),
                    {
                        sticky: true,
                        direction: "top"
                    }
                ));
            }
        }

        zonesLayerGroup.addTo(leafletMap);
    }




    return (
        <div id='map' className="all-app-remaining-height" />
    );
});

export default ZonesMap;