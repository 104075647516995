import React, { useContext, useEffect } from 'react';

import DronesAuthorizationsColumn from '../components/dronesauthorizationscolumn.component';
import DronesColumn from '../components/dronescolumn.component';
import { DronesDataStoreContext } from '../../../stores/drones.store';
import { ModelsDataStoreContext } from '../../../stores/models.store';

import { observer } from 'mobx-react-lite';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const DronesPage = observer(() => {
    const dronesStore = useContext(DronesDataStoreContext);
    const modelsDataStore = useContext(ModelsDataStoreContext);

    useEffect(() => {
        dronesStore.drones = [];
        dronesStore.getDrones();
        modelsDataStore.getModels();
    }, [])

    return (
        <Container className="all-app-remaining-height" style={{ padding: "0px", height: "100%" }} fluid>
            <Row noGutters style={{height: "100%"}}>
                <Col xs={12} className="all-app-remaining-height" style={{ paddingTop: "20px", paddingBottom: "20px", paddingRight: "100px", paddingLeft: "100px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Row style={{ height: '100%', width: '100%' }}>
                        <DronesColumn />
                    </Row>
                </Col>

            </Row>
        </Container>
    )
})
/*<Col xs={6} className="all-app-remaining-height" style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                    <DronesAuthorizationsColumn />
                </Col>*/
export default DronesPage;