import React, { useState, useContext, SyntheticEvent } from 'react';

import { observer } from 'mobx-react-lite';
import { ZonesDataStoreContext } from '../../../stores/zonesdata.store';
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCircle, faSquare, faPen } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';

const ZonesCreateForm = observer(() => {

    const [t, i18n] = useTranslation("common");

    const zonesDataStore = useContext(ZonesDataStoreContext);
    const [name, setName] = useState("");
    const [parentZone, setParentZone] = useState("");
    const [description, setDescription] = useState("");


    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (!zonesDataStore.newZone) alert(t("pages.zones.form.alert-invalid-zone"));
        else if (!parentZone || !name || !description) alert(t("pages.zones.form.alert-invalid-fields"));
        else {
            var pZone: any = JSON.parse(parentZone);
            let secLevel: any = { "WARNING": "ALERT", "ALERT": "ALARM" };
            zonesDataStore.createZone(pZone._id, name, zonesDataStore.newZoneType, secLevel[pZone.securityLevel], toJS(zonesDataStore.newZone), description);
            setTimeout(() => zonesDataStore.getZones(), 500);
            setParentZone("");
            zonesDataStore.newZone = null;
        }
    }

    return (
        <Accordion style={{ marginBottom: "30px" }}>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="-1" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h5 style={{ margin: "0" }}>{t("pages.zones.form.header")}</h5>
                    <FontAwesomeIcon icon={faAngleDown} />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="-1">
                    <Card.Body style={{ padding: '1.25rem 1.25rem' }}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formGroupSN">
                                <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.zones.form.name")}</Form.Label>
                                <Form.Control onChange={(v) => setName(v.target.value)} type="text" placeholder={t("pages.zones.form.name-placeholder")} />
                            </Form.Group>
                            <Form.Group controlId="formGroupZones" style={{ display: "flex", flexDirection: "column" }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.zones.form.reference-zone")}</Form.Label>
                                <Form.Control value={parentZone} as="select" onChange={((v: any) => setParentZone(v.target.value))}>
                                    <option value={""}>-----------</option>
                                    {
                                        zonesDataStore.zones.map((zone: any) => {
                                            if (zone.securityLevel !== "ALARM") {
                                                return (<option value={JSON.stringify(zone)}>{zone.name}</option>)
                                            }
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formGroupDescription">
                                <Form.Label style={{ fontWeight: 'bold' }}>{t("pages.zones.form.description")}</Form.Label>
                                <Form.Control as="textarea" onChange={(v) => setDescription(v.target.value)} />
                            </Form.Group>
                            <Button block type="submit">{t("pages.zones.form.btn-create")}</Button>
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
});

export default ZonesCreateForm;