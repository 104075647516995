import React from 'react'
import { Button } from 'react-bootstrap'

interface Props {
    message: string
    action: () => void
    buttonTitle: string
}

export default function AlertPopup(props: Props) {
    return (
        <div style={{
            width: "fit-content", backgroundColor: "white", color: "black",
            borderRadius: "1rem", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            padding: "1rem", display: "flex", alignItems: "center", 
            justifyContent: "center", flexDirection: "column",
            marginTop: "100vh", animation: "appear 0.5s ease forwards"
        }}>
            <h1 style={{fontSize: "xx-large"}}>{ props.message }</h1>
            <Button
                style={{marginTop:"1rem"}}
                variant="primary"
                onClick={() => props.action()}
            >
                { props.buttonTitle }
            </Button>
        </div>
    )
}
