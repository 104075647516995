import { observable } from 'mobx';
import { createContext } from 'react';
import { getZones, createZone, removeZone } from '../api';


export enum ZonesStatus {
    NotYet,
    Checking,
    Waiting,
    Succeded,
    Failed
}


class ZonesDataStorage {
    @observable status = ZonesStatus.NotYet;
    @observable zones: any = [];
    @observable newZoneType: String = "";
    @observable newZone: any;
    @observable newZoneStatus = ZonesStatus.NotYet;


    async getZones() {
        this.status = ZonesStatus.Checking;
        let response = await getZones();
        if (!response[0]) {
            this.zones = [];
            this.status = ZonesStatus.Failed;
            return;
        }
        var auxZones: any = [];
        auxZones.push(response[1]);
        let alertZones = response[1].children;
        delete response[1].children;
        for (var j = 0; j < alertZones.length; j++) {
            auxZones.push(alertZones[j]);
            let alarmZones = alertZones[j].children;
            delete alertZones[j].children;
            for (var k = 0; k < alarmZones.length; k++) {
                auxZones.push(alarmZones[k]);
            }
        };
        this.zones = auxZones;
        this.status = ZonesStatus.Succeded;
        return;
    }

    async createZone(parentId: String, zoneName: String, zoneType: String, securityLevel: String, location: any, description: String){
        var body: any;
        if (zoneType === "CIRCLE") {
            body = {name: zoneName, securityLevel: securityLevel, center: location.center, radius: location.radius ,type: zoneType, description: description};
        } else {
            body = {name: zoneName, securityLevel: securityLevel, perimeter: location.perimeter, type: zoneType, description: description};
        }
        
        let response = await createZone(parentId, body);
        if (!response) {
            alert("No se ha podido crear la zona, rellena correctamente todos los campos")
            return;
        }
        return;
    }

    async removeZone(zoneId: String){
        let response = await removeZone(zoneId);
        if (!response) {
            //console.log("error creando zona")
            return;
        }
        return;
    }
}

export const ZonesDataStoreContext = createContext(new ZonesDataStorage());