import React, { useContext, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { RegistersDataStoreContext, RegistersStatus } from '../../../stores/registersdata.store';
import { MapAnimationsStoreContext } from '../../../stores/mapanimations.store';
import RegistersFilterForm from './registersFilterForm.component';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCrosshairs, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { faDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { DetectorsStoreContext } from '../../../stores/detectors.store';
import { useTranslation } from 'react-i18next';

const RegistersColumn = observer((props: any) => {

    const [t, i18n] = useTranslation("common");

    const mapanimationsStore = useContext(MapAnimationsStoreContext);
    const detectorsStore = useContext(DetectorsStoreContext);
    const registersDataStore = useContext(RegistersDataStoreContext);
    const [actualPage, setActualPage] = useState(1);

    
    useEffect(() => {
        registersDataStore.queryParams === '' ? registersDataStore.queryParams += '?pageNumber=' + actualPage.toString() : registersDataStore.queryParams += '&pageNumber=' + actualPage.toString();
        registersDataStore.getRegisters(registersDataStore.queryParams);
    }, []);

    let daysOfTheWeek: any = { "Mon": t("pages.detections.filters.days-mon"), "Tue": t("pages.detections.filters.days-tue"), "Wen": t("pages.detections.filters.days-wed"), "Thu": t("pages.detections.filters.days-thu"), "Fri": t("pages.detections.filters.days-fri"), "Sat": t("pages.detections.filters.days-sat"), "Sun": t("pages.detections.filters.days-sun") };

    function getMoreRegisters() {
        let newActualPage = actualPage + 1;
        if (registersDataStore.queryParams.includes('pageNumber=')) {
            var init = registersDataStore.queryParams.indexOf('pageNumber');
            init = registersDataStore.queryParams.indexOf('=', init);
            registersDataStore.queryParams = registersDataStore.queryParams.substring(0, init + 1);
            registersDataStore.queryParams += newActualPage.toString();
            setActualPage(newActualPage);
        } else {
            setActualPage(2);
            registersDataStore.queryParams === '' ? registersDataStore.queryParams += '?pageNumber=2' : registersDataStore.queryParams += 'pageNumber=2';
        }
        registersDataStore.getMoreRegisters(registersDataStore.queryParams);
    }


    function setActiveDetection(detection: any, idx: any, drone: any, center: any) {
        registersDataStore.activeController[idx] = !registersDataStore.activeController[idx];
        if (registersDataStore.activeController[idx]) {
            registersDataStore.activeRegisters.push({ id: idx, detection: detection, drone: drone, center: center });
        }
        else {
            for (var i = registersDataStore.activeRegisters.length - 1; i >= 0; --i) {
                if (registersDataStore.activeRegisters[i].id == idx) {
                    registersDataStore.activeRegisters.splice(i, 1);
                }
            }
        }
        if (!Object.values(registersDataStore.activeController).includes(true)) {
            mapanimationsStore.focusOnCenter()
        }
    }

    async function downloadPDF(id: string, sn: string) {
        props.onDownloadStarted()
        await registersDataStore.getPDF(id, sn, new Date().getTimezoneOffset())
        props.onDownloadEnded()
    }

    async function downloadKML(id: string, sn: string) {
        props.onDownloadStarted()
        await registersDataStore.getKML(id, sn)
        props.onDownloadEnded()
    }


    var registersItems = [];
    for (var i = 0; i < registersDataStore.registers.length; i++) {
        let register = registersDataStore.registers[i];
        let idx = i;
        var dateIni = new Date(registersDataStore.registers[i].dateIni).toLocaleString();
        var dateFin = new Date(registersDataStore.registers[i].dateFin).toLocaleString();
        let modelImage = registersDataStore.registers[i].drone.model ? registersDataStore.registers[i].drone.model.image : "unknown-drone.png";

        let color = "orange"
            
            if (registersDataStore?.registers?.[i]?.drone?.type) {
                switch (registersDataStore.registers[i].drone.type) {
                    case "FRIEND":
                    case "AUTHORIZED":
                        color = "green"
                        break;
    
                    case "STOLEN":
                        color = "chocolate"
                        break;
                    
                    case "FAKE":
                    case "NEUTRALIZED":
                        color = "black";
                        break;
                    
                    case "ENEMY":
                        color = "red";
                        break;
                    
                    case "NOTIFY":
                        color = "blue";
                        break;
    
                    default:
                        color = "orange";
                }

            }
        registersItems.push(
            <Card key={dateIni + "_" + register.drone.sn} style={{ marginTop: "10px" }}>
                <Card.Header style={{ display: "flex", alignItems: "center", backgroundColor: registersDataStore.activeController[i] ? "#DCDCDC" : "" }}>
                    <div style={{ backgroundColor: color, width: "5px", height: "30px", marginRight: "1rem" }} />
                    <img src={'/api/img/models/' + modelImage} style={{ width: "90px", height: "90px" }}></img>
                    <Accordion.Toggle as={Button} variant="link" eventKey={i.toString()} style={{ width: "-webkit-fill-available" }}>
                        {dateIni} <br /> {registersDataStore.registers[i]['drone']['sn'] ? registersDataStore.registers[i]['drone']['sn'] : registersDataStore.registers[i]['drone']['mac'] ? registersDataStore.registers[i]['drone']['mac'] : registersDataStore.registers[i]?.drone?.model?.brand && registersDataStore.registers[i]?.communicationProtocol ? `${registersDataStore.registers[i].drone.model.brand} / ${registersDataStore.registers[i].communicationProtocol}` : ""}
                    </Accordion.Toggle>
                    <OverlayTrigger
                        key={dateIni + "_" + register.drone.sn + "_trig"}
                        placement='bottom'
                        overlay={
                            <Tooltip id='centerLocation'>
                                {t("pages.detections.detection.btn-show-detection-hover")}
                            </Tooltip>
                        }
                    >
                        <Button
                            onClick={() => { setActiveDetection(register.route, idx, register.drone, register.center); }}
                            style={{ marginLeft: "auto", backgroundColor: registersDataStore.activeController[i] ? "" : "grey", border: "none" }} >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        key={dateIni + "_" + register.drone.sn + "_trig2"}
                        placement='bottom'
                        overlay={
                            <Tooltip id='centerLocation'>
                                {t("pages.detections.detection.btn-center-detection-hover")}
                            </Tooltip>
                        }
                    >
                        <Button
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                                registersDataStore.activeRegisters.length = 0;
                                setTimeout(() => {
                                    for (var j = 0; j < registersDataStore.activeController.length; j++) registersDataStore.activeController[j] = false;
                                    //mapanimationsStore.focusOnDetection([null, register.route]);
                                    mapanimationsStore.focusOnDetection([null, register], detectorsStore.detectors);
                                    setActiveDetection(register.route, idx, register.drone, register.center);
                                }, 100);
                            }}>
                            <FontAwesomeIcon icon={faCrosshairs} />
                        </Button>
                    </OverlayTrigger>
                </Card.Header>
                <Accordion.Collapse eventKey={i.toString()}>
                    <Card.Body style={{ padding: "0rem 1.25rem" }}>
                        {
                            registersDataStore.registers[i].drone.type === "FAKE" ?
                                <div style={{ backgroundColor: "yellow", marginTop: "10px", padding: "10px", borderRadius: "7px" }}>
                                    <span>El sistema considera que esta detección podría ser fraudulenta</span>
                                </div> :
                                null
                        }
                        <Table responsive style={{ textAlign: "left", margin: "20px" }}>
                            <tbody>
                                {
                                    /*registersDataStore.registers[i].drone.owner ?
                                        <tr>
                                            <td style={{ fontWeight: "bold", borderTop: "none", width: "50%" }}>Autorizado por</td>
                                            <td style={{ borderTop: "none" }}>{
                                                String(registersDataStore.registers[i].drone.owner)
                                            }</td>
                                        </tr>
                                        :
                                        null*/
                                }

                                {/* 1. MANUFACTURER */}
                                <tr>
                                    <td style={{ fontWeight: "bold", borderTop: "none", width: "50%" }}>{t("drone.brand")}</td>
                                    <td style={{ borderTop: "none" }}>{
                                        registersDataStore.registers[i].drone.model ?
                                            String(registersDataStore.registers[i].drone.model.brand)
                                            :
                                            String(t("drone.brand-unknown"))
                                    }</td>
                                </tr>

                                {/* 2. MODEL */}
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("drone.model")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{
                                        registersDataStore.registers[i].drone.model ?
                                            String(registersDataStore.registers[i].drone.model.model)
                                            :
                                            String(t("drone.model-unknown"))
                                    }</td>
                                </tr>

                                {/* 3. WEIGHT */}
                                {registersDataStore.registers[i].drone.model ?
                                    registersDataStore.registers[i].drone.model.weight ?
                                        <tr>

                                            <td style={{ fontWeight: "bold" }}>{t("drone.weight")}</td>
                                            <td style={{ verticalAlign: "middle" }}>
                                                {String(registersDataStore.registers[i].drone.model.weight)}
                                            </td>
                                        </tr>
                                        :
                                        null
                                    :
                                    null
                                }

                                {/* 4. SERIAL NUMBER */}
                                {
                                    registersDataStore.registers[i]['drone']['sn'] ? (
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>{t("drone.identifier")}</td>
                                            <td style={{ verticalAlign: "middle" }}>{registersDataStore.registers[i]['drone']['sn']}</td>
                                        </tr>
                                    ) : null
                                }

                                {/* 4.1 REMOTE ID SERIAL NUMBER */}
                                {
                                    registersDataStore.registers[i]['drone']['uid'] ? (
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>{t("drone.rIdentifier")}</td>
                                            <td style={{ verticalAlign: "middle" }}>{registersDataStore.registers[i]['drone']['uid']}</td>
                                        </tr>
                                    ) : null
                                }
                                

                                {/* 5. MAC */}
                                {registersDataStore.registers[i]['drone'] && registersDataStore.registers[i]['drone']['mac'] ? (<tr>
                                    <td style={{ fontWeight: "bold" }}>{t("drone.mac")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{registersDataStore.registers[i]['drone']['mac']}</td>
                                </tr>) : null}


                                {/* 6. PROTOCOL */}
                                {registersDataStore.registers[i] && registersDataStore.registers[i].communicationProtocol ? (
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>{t("drone.vendor")}</td>
                                        <td style={{ verticalAlign: "middle" }}>{
                                            Array.isArray(registersDataStore.registers[i].communicationProtocol) ? registersDataStore.registers[i].communicationProtocol.join(", ") : registersDataStore.registers[i].communicationProtocol
                                        }</td>
                                    </tr>
                                ) : null}

                                {/* 7. OPERATOR */}
                                {
                                    registersDataStore.registers[i].operatorId ?
                                        <tr>
                                            <td style={{ fontWeight: "bold", width: "50%" }}>{t("detection.operator")}</td>
                                            <td style={{  }}>{
                                                String(registersDataStore.registers[i].operatorId)
                                            }</td>
                                        </tr>
                                        :
                                        null
                                }
                                
                                {/* <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("drone.uuid")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{registersDataStore.registers[i]['drone']['uuid']}</td>
                                </tr> */}


                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("detection.start-time")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{dateIni}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("detection.end-time")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{dateFin}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("detection.day")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{daysOfTheWeek[registersDataStore.registers[i]['dayOfTheWeek']]}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("detection.points")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{registersDataStore.registers[i]['route'].length}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("detection.min-height")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{registersDataStore.registers[i].minHeight} m</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>{t("detection.max-height")}</td>
                                    <td style={{ verticalAlign: "middle" }}>{registersDataStore.registers[i].maxHeight} m</td>
                                </tr>
                                {
                                    registersDataStore.registers[i].distanceToDetector ?
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>{t("detection.distance-first-point")}</td>
                                            <td style={{ verticalAlign: "middle" }}>{registersDataStore.registers[i].distanceToDetector.toFixed(0)} m</td>
                                        </tr>
                                        : null
                                }
                            </tbody>
                        </Table>
                        <Row>
                            <Col>
                                <Button block onClick={() => downloadPDF(register._id, register.drone.sn)} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <FontAwesomeIcon icon={faFilePdf} />
                                    &nbsp; {t("pages.detections.detection.btn-download-pdf")}
                                </Button>
                            </Col>
                            <Col>
                                <Button block onClick={() => downloadKML(register._id, register.drone.sn)} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <FontAwesomeIcon icon={faDownload} />
                                    &nbsp; {t("pages.detections.detection.btn-download-kml")}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }

    return (
        <Card style={{ width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0)", border: "none" }}>
            <Card.Body style={{ textAlign: "center", overflowY: "scroll" }}>
                <RegistersFilterForm
                    onDownloadStarted={() => props.onDownloadStarted()}
                    onDownloadEnded={() => props.onDownloadEnded()}
                />
                {
                    registersDataStore.status === RegistersStatus.Failed ?
                        <Card style={{ marginTop: "10px" }}>
                            <Card.Body>
                                {t("pages.detections.filters.without-results")}
                            </Card.Body>
                        </Card>
                        :
                        (registersDataStore.status === RegistersStatus.Succeded || registersDataStore.status === RegistersStatus.LoadingMore) ?
                            <Accordion>
                                {registersItems}
                            </Accordion>
                            :
                            <Container style={{ marginTop: "100px" }}>
                                <Row>
                                    <Col xs={5} />
                                    <Col>
                                        <Spinner animation="grow" variant="primary" style={{ width: "100%", paddingTop: "100%", overflowY: "hidden" }} />
                                    </Col>
                                    <Col xs={5} />
                                </Row>
                            </Container>
                }
                {
                    registersDataStore.status === RegistersStatus.Succeded ?
                        <Button style={{ marginTop: "10px" }} onClick={() => getMoreRegisters()}> <FontAwesomeIcon icon={faArrowCircleDown} /> <span style={{ paddingLeft: "7px" }}>{t("pages.detections.btn-load-detections")}</span></Button>
                        :
                        registersDataStore.status === RegistersStatus.LoadingMore ?
                            <Spinner animation="border" variant="primary" style={{ marginTop: "10px" }} />
                            :
                            null
                }

            </Card.Body>
        </Card>
    );
});

export default RegistersColumn;


/*<div style={{ height: "calc(100% - 20px)", overflowY: "hidden", marginTop: "20px", marginRight: "20px" }}>
            <RegistersFilterForm />
            {
                registersDataStore.status === RegistersStatus.Failed ?
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Body>
                            Esta consulta no ha obtenido resultados.
                            Por favor pruebe de nuevo con distintos parámetros.
                    </Card.Body>
                    </Card>
                    :
                    registersDataStore.status == 3 ?
                        <div style={{ maxHeight: "88%", overflowY: "scroll", marginTop: "30px" }}>
                            <Accordion>
                                {registersItems}
                            </Accordion>
                        </div>
                        :
                        <Container style={{ marginTop: "100px" }}>
                            <Row>
                                <Col xs={5} />
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{ width: "100%", paddingTop: "100%", overflowY: "hidden" }} />
                                </Col>
                                <Col xs={5} />
                            </Row>
                        </Container>
            }
        </div>*/